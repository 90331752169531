import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-crear-tipo-proyecto',
  templateUrl: './crear-tipo-proyecto.component.html'
})
export class CrearTipoProyectoComponent implements OnInit {
  idTipo;
  nombreProyecto;
  tipoProyecto;
  clienteProyecto;
  comentarios;
  clientClass;
  idcliente;
  tipos = [
    { tipo: 'SLS' },
    { tipo: 'SHS' },
    { tipo: 'MONITOREO' },
    { tipo: 'SOFTWARE' },
    { tipo: 'SUMINISTRO' },
  ];
  constructor(public global: GlobalService,
    public route: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.idcliente = this.global.Id_Client;
  }

  goBack() {
    window.history.back();
  }
  crear() {
    this.spinner.show();
    setTimeout(() => {
      if (this.nombreProyecto == undefined || this.tipoProyecto == undefined || this.clienteProyecto == undefined || this.comentarios == undefined) {

        this.alerta.descErr('Ningún campo puede ir vacío');

      } else {
        const query = 'INSERT INTO db_liwa.tipoProyecto (Nombre, Tipo, Cliente, Comentarios, IsDelete, IsActive, Id_Client)' +
          ' VALUES (\'' +
          this.nombreProyecto + '\',\'' +
          this.tipoProyecto + '\',\'' +
          this.clienteProyecto + '\',\'' +
          this.comentarios + '\', \'' +
          0 + '\',\'' +
          1 + '\',\'' +
          this.idcliente + '\');';
        const pdata1 = { option: 'insertar', texto: query };
        this.global.consultar(pdata1, (err, response) => {
          console.log(response, query);
          if (response) {
            this.spinner.hide();
            this.alerta.descValid('El proyecto se creó éxitosamente');
            this.route.navigateByUrl('/proyecto');
          }else if(response == null || response == undefined){
            this.spinner.hide();
            this.alerta.descErr('Error Validar de nuevo por favor');
          }
        });
      }
      this.spinner.hide();
    }, 500);
  }

}
