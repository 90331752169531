import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-jd',
  templateUrl: './jd.component.html'
})
export class JdComponent implements OnInit {
  Items=new Array();
  clientClass;
  listActive = true;
  mapActive = false;
  sena = new Array();
  latitude = 6.5997156;
  longitude = -71.4886296;
  zoom = 8.5;
  color;
  operacion = true;
  colorg;
  Style;
  mapType;
  informesActive = false;
  isHis = true;
  isCom = false;
  isLevel = false;
  constructor(public global: GlobalService,
    public route: Router, private spinner: NgxSpinnerService) {

      this.global.initData();
    }

  ngOnInit(): void {
    this.mapType = 'roadmap';
    this.Style = 'all';
    this.spinner.show();
    this.clientClass = this.global.CLIENTDATALIWA[8];
    setTimeout(() => {
      this.spinner.hide();
      window.scrollTo(0,0);
    }, 1000);
    const pdata9 = { option: 'liwa'};
    console.log('LIWA', pdata9);
    this.global.consultar(pdata9, (err9, response9) => {
    console.log('Datos Liwa', response9);
    this.sena = response9;
    });

  }
  ver(data){
    console.log(data);
    this.route.navigateByUrl('/sedes');
  }

encender(){
  this.operacion=true;
}

apagar(){
  this.operacion=false;
}
  isList(){
    if (this.listActive == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.listActive = true;
    } else {
      this.listActive = false;
   }
}

isHsit(){
  if (this.isHis == false) {
    this.isCom = false;
    this.isHis = true;
    this.isLevel = false;
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
    let background = 'rgba(255, 99, 132, 0.2)';
    let color = 'rgba(255, 99, 132, 1)';
    let background2 = 'rgba(54, 162, 235, 0.1)';
    let color2 = 'rgba(54, 162, 235, 1)';
    setTimeout(() => {
      const canvas = document.getElementById('myChart') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
      //   var mixedChart = new Chart(ctx, {
      //     type: 'bar',
      //     data: {
      //         datasets: [{
      //             label: 'Consumo',
      //             data: [
      //               '688.5',
      //               '701.25',
      //               '739.5',
      //               '726.75',
      //               '736.95',
      //               '701.25',
      //               '711.45',
      //               '698.7',
      //               '696.15',
      //               '734.4',
      //               '724.2',
      //               '721.65',
      //               '703.8',
      //               '742.05',
      //               '698.7',
      //               '703.8',
      //               '734.4',
      //               '726.75',
      //               '711.45',
      //               '691.05',
      //               '708.9',
      //               ],
      //             backgroundColor: background,
      //             borderColor: color,
      //             borderWidth: 1
      //       ,
      //         }, {
      //             label: 'Generación',
      //             data: [
      //               '810',
      //               '825',
      //               '870',
      //               '855',
      //               '867',
      //               '825',
      //               '837',
      //               '822',
      //               '819',
      //               '864',
      //               '852',
      //               '849',
      //               '828',
      //               '873',
      //               '822',
      //               '828',
      //               '864',
      //               '855',
      //               '837',
      //               '813',
      //               '834',
      //               ],
      //             backgroundColor: background2,
      //             borderWidth: 1,
      //             borderColor: color2,
      //             type: 'line'
      //         }],
      //         labels: ['31/03/2021','1/04/2021','2/04/2021','3/04/2021','4/04/2021','5/04/2021','6/04/2021','7/04/2021','8/04/2021','9/04/2021','10/04/2021','11/04/2021','12/04/2021','13/04/2021','14/04/2021','15/04/2021','16/04/2021','17/04/2021','18/04/2021','19/04/2021','20/04/2021']
      //     },
      //     options : {
      //       scales: {
      //         xAxes: [{
      //           gridLines: {
      //               color:this.colorg, // Eje x color verde
      //           },
      //           ticks: {
      //               fontColor: this.color,
      //           },

      //           display: true,
      //           scaleLabel: {
      //               fontColor: this.color,
      //               display: true,
      //               labelString: 'Día'
      //           }
      //       }],
      //       yAxes: [{
      //           ticks: {
      //               fontColor: this.color,
      //               beginAtZero: true
      //           },
      //           scaleLabel: {
      //               display: true,
      //               labelString: 'KWh',
      //               fontColor: this.color,
      //           },
      //           gridLines: {
      //               color: this.colorg,
      //               display: false
      //           }
      //       }]
      //       }
      //     }
      // });
    }, 300);
  } else {
    this.isHis = false;
 }
}

isComb(){
  if (this.isCom == false) {
    this.isHis = false;
    this.isCom = true;
    this.isLevel = false;
    setTimeout(() => {
      const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    let background = 'rgba(75, 192, 192, 0.2)';
    let color = 'rgba(75, 192, 192, 1)';
const ctx = canvas.getContext('2d');
// let myChart = new Chart(ctx, {
// type: 'bar',
// data: {
//   labels: ['31/03/2021','1/04/2021','2/04/2021','3/04/2021','4/04/2021','5/04/2021','6/04/2021','7/04/2021','8/04/2021','9/04/2021','10/04/2021','11/04/2021','12/04/2021','13/04/2021','14/04/2021','15/04/2021','16/04/2021','17/04/2021','18/04/2021','19/04/2021','20/04/2021'],
//   datasets: [{
//       label : 'Galones',
//       data: [
// '69.3008',
// '70.036',
// '72.2416',
// '71.5064',
// '72.09456',
// '70.036',
// '70.62416',
// '69.88896',
// '69.74192',
// '71.94752',
// '71.35936',
// '71.21232',
// '70.18304',
// '72.38864',
// '69.88896',
// '70.18304',
// '71.94752',
// '71.5064',
// '70.62416',
// '69.44784',
// '70.47712',

//       ],
//       backgroundColor: background,
//       borderColor: color,
//       borderWidth: 1
//   }]
// },
// options: {
//   legend: {
//       labels: {
//           fontColor: this.color,
//       }
//   },
//   scales: {
//       xAxes: [{
//           ticks: {
//               fontColor: this.color,
//           },
//           gridLines: {
//               color: this.colorg,
//           },
//           display: true,
//           scaleLabel: {
//               fontColor: this.color,
//               display: true,
//               labelString: 'Día'
//           }
//       }],
//       yAxes: [{
//           gridLines: {
//               color: this.colorg,
//               display: false
//       },
//           ticks: {
//               fontColor: this.color,
//               beginAtZero: true
//           },
//           scaleLabel: {
//               fontColor: this.color,
//               display: true
//           },
//           }]
//   }
// }
// });
    }, 300);
  } else {
    this.isCom = false;
 }
}

isLev(){
  if (this.isLevel == false) {
    this.isHis = false;
    this.isCom = false;
    this.isLevel = true;
    setTimeout(() => {
      const canvas = document.getElementById('myChart3') as HTMLCanvasElement;
    let background = 'rgba(50, 50, 192, 0.2)';
    let color = 'rgba(50, 50, 192, 1)';
const ctx = canvas.getContext('2d');
// let myChart = new Chart(ctx, {
// type: 'bar',
// data: {
//   labels: ['31/03/2021','1/04/2021','2/04/2021','3/04/2021','4/04/2021','5/04/2021','6/04/2021','7/04/2021','8/04/2021','9/04/2021','10/04/2021','11/04/2021','12/04/2021','13/04/2021','14/04/2021','15/04/2021','16/04/2021','17/04/2021','18/04/2021','19/04/2021','20/04/2021'],
//   datasets: [{
//       label : 'Porcentaje del Tanque',
//       data: [
// '37.77284848',
// '37.88424242',
// '38.21842424',
// '38.1070303',
// '38.19614545',
// '37.88424242',
// '37.97335758',
// '37.86196364',
// '37.83968485',
// '38.17386667',
// '38.08475152',
// '38.06247273',
// '37.90652121',
// '38.24070303',
// '37.86196364',
// '37.90652121',
// '38.17386667',
// '38.1070303',
// '37.97335758',
// '37.79512727',
// '37.95107879',

//       ],
//       backgroundColor: background,
//       borderColor: color,
//       borderWidth: 1
//   }]
// },
// options: {
//   legend: {
//       labels: {
//           fontColor: this.color,
//       }
//   },
//   scales: {
//       xAxes: [{
//           ticks: {
//               fontColor: this.color,
//           },
//           gridLines: {
//               color: this.colorg,
//           },
//           display: true,
//           scaleLabel: {
//               fontColor: this.color,
//               display: true,
//               labelString: 'Día'
//           }
//       }],
//       yAxes: [{
//           gridLines: {
//               color: this.colorg,
//               display: false
//       },
//           ticks: {
//               fontColor: this.color,
//               beginAtZero: true,
//                 suggestedMin: 0,
//                 suggestedMax: 100
//           },
//           scaleLabel: {
//               fontColor: this.color,
//               display: true
//           },
//           }]
//   }
// }
// });
    }, 300);
  } else {
    this.isLevel = false;
 }
}


  isMap(){
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
    let background = 'rgba(255, 99, 132, 0.2)';
    let color = 'rgba(255, 99, 132, 1)';
    let background2 = 'rgba(54, 162, 235, 0.1)';
    let color2 = 'rgba(54, 162, 235, 1)';
    this.isHis = true;
    this.isCom = false;
    if (this.mapActive == false) {
        this.mapActive = true;
        this.informesActive = false;
        this.listActive = false;
        setTimeout(() => {
        const canvas = document.getElementById('myChart') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
      //   var mixedChart = new Chart(ctx, {
      //     type: 'bar',
      //     data: {
      //         datasets: [{
      //             label: 'Consumo',
      //             data: [
      //               '421.2',
      //               '429',
      //               '452.4',
      //               '444.6',
      //               '450.84',
      //               '429',
      //               '435.24',
      //               '427.44',
      //               '425.88',
      //               '449.28',
      //               '443.04',
      //               '441.48',
      //               '430.56',
      //               '453.96',
      //               '427.44',
      //               '430.56',
      //               '449.28',
      //               '444.6',
      //               '435.24',
      //               '422.76',
      //               '433.68',
      //               ],
      //             backgroundColor: background,
      //             borderColor: color,
      //             borderWidth: 1
      //       ,
      //         }, {
      //             label: 'Generación',
      //             data: [
      //               '810',
      //               '825',
      //               '870',
      //               '855',
      //               '867',
      //               '825',
      //               '837',
      //               '822',
      //               '819',
      //               '864',
      //               '852',
      //               '849',
      //               '828',
      //               '873',
      //               '822',
      //               '828',
      //               '864',
      //               '855',
      //               '837',
      //               '813',
      //               '834',
      //               ],
      //             backgroundColor: background2,
      //             borderWidth: 1,
      //             borderColor: color2,
      //             type: 'line'
      //         }],
      //         labels: ['31/03/2021','1/04/2021','2/04/2021','3/04/2021','4/04/2021','5/04/2021','6/04/2021','7/04/2021','8/04/2021','9/04/2021','10/04/2021','11/04/2021','12/04/2021','13/04/2021','14/04/2021','15/04/2021','16/04/2021','17/04/2021','18/04/2021','19/04/2021','20/04/2021']
      //     },
      //     options : {
      //       scales: {
      //         xAxes: [{
      //           gridLines: {
      //               color:this.colorg, // Eje x color verde
      //           },
      //           ticks: {
      //               fontColor: this.color,
      //           },

      //           display: true,
      //           scaleLabel: {
      //               fontColor: this.color,
      //               display: true,
      //               labelString: 'Día'
      //           }
      //       }],
      //       yAxes: [{
      //           ticks: {
      //               fontColor: this.color,
      //               beginAtZero: true
      //           },
      //           scaleLabel: {
      //               display: true,
      //               labelString: 'KWh',
      //               fontColor: this.color,
      //           },
      //           gridLines: {
      //               color: this.colorg,
      //               display: false
      //           }
      //       }]
      //       }
      //     }
      // });
        }, 300);
    } else {
      this.mapActive = false;
    }
  }

  isInforme(){
    if (this.informesActive == false) {
      this.mapActive = false;
      this.informesActive = true;
      this.listActive = false;
    } else {
       this.informesActive = false;
    }
  }




}
