import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-inicial',
  templateUrl: './inicial.component.html',
  styleUrls: ['./inicial.component.scss']
})
export class InicialComponent implements OnInit {
  medidores = [];
  MEDIDORESEXCEL;
  clientClass;
  Subir = false;
  Registrar = false;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idProsec == undefined || this.global.nombreProyecto == undefined) {
      let valTemp: any;
      valTemp = sessionStorage.getItem('secTemp');
      if (valTemp != null) {
        valTemp = valTemp.split(',');
        this.global.idProsec = valTemp[0];
        this.global.nombreProyecto = valTemp[1];
        setTimeout(() => {
          let tempVar: any = [];
          tempVar.push(this.global.idProsec);
          tempVar.push(this.global.nombreProyecto);
          sessionStorage.setItem('secTemp', tempVar);
          this.clientClass = this.global.CLIENTDATALIWA[8];
          const pdata1 = { option: 'lis_table', idProyecto: this.global.idSeccional };
          console.log('listado', pdata1);
          this.global.consultar(pdata1, (err1, response1) => {
            console.log('Datos listado', response1);
            this.medidores = response1;
            $(function () {
              this.table = $('#seccionalTable').DataTable({
                'responsive': false,
                'destroy': true,
                'retrieve': true,
                'paging': true,
                'info': false,
                'pagingType': 'numbers',
                'language': {
                  'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
                }
              });
            });
          });

          this.spinner.hide();
        }, 600);
      } else {
        this.ruta.navigateByUrl('/proyecto');
        this.spinner.hide();
      }
    } else {
      setTimeout(() => {
        let tempVar: any = [];
        tempVar.push(this.global.idProsec);
        tempVar.push(this.global.nombreProyecto);
        sessionStorage.setItem('secTemp', tempVar);
        this.clientClass = this.global.CLIENTDATALIWA[8];
        const pdata1 = { option: 'lis_table', idProyecto: this.global.idSeccional };
        console.log('listado', pdata1);
        this.global.consultar(pdata1, (err1, response1) => {
          console.log('Datos listado', response1);
          this.medidores = response1;
          $(function () {
            this.table = $('#seccionalTable').DataTable({
              'responsive': false,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              'info': false,
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              }
            });
          });
        });

        this.spinner.hide();
      }, 600);
    }
  }

  SubirExcel() {
    this.Subir = true;
  }
  arrayBuffer:any;
  file:File;
  incomingfile(event)
  {
  this.file= event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
          var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet,{raw:true});
          if(MEDIDORESEXCEL[0].hasOwnProperty('Apellidos')){
            console.log('Si Apellidos')
            if(MEDIDORESEXCEL[0].hasOwnProperty('Cedula')){
              console.log('SI Cedula')
              if(MEDIDORESEXCEL[0].hasOwnProperty('Comunidad')){
                console.log('SI Comunidad')
                if(MEDIDORESEXCEL[0].hasOwnProperty('Departamento')){
                  console.log('SI Departamento')
                  if(MEDIDORESEXCEL[0].hasOwnProperty('Latitud')){
                    console.log('SI Latitud')
                    if(MEDIDORESEXCEL[0].hasOwnProperty('Longitud')){
                      console.log('SI Longitud')
                      if(MEDIDORESEXCEL[0].hasOwnProperty('Municipio')){
                        console.log('SI Municipio')
                        if(MEDIDORESEXCEL[0].hasOwnProperty('Nombres')){
                          console.log('SI Nombres')
                          if(MEDIDORESEXCEL[0].hasOwnProperty('Vereda')){
                            console.log('SI Vereda')
                            this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                            this.Registrar = true;
                          } else {
                            console.log('No Vereda')
                            this.alerta.excelErr('Vereda');
                          }
                        } else {
                          console.log('No Nombres')
                          this.alerta.excelErr('Nombres');
                        }
                      } else {
                        console.log('No Municipio')
                        this.alerta.excelErr('Municipio');
                      }
                    } else {
                      console.log('No Longitud')
                      this.alerta.excelErr('Longitud');
                    }
                  } else {
                    console.log('No Latitud')
                    this.alerta.excelErr('Latitud');
                  }
                } else {
                  console.log('No Departamento')
                  this.alerta.excelErr('Departamento');
                }
              } else {
                console.log('No Comunidad')
                this.alerta.excelErr('Comunidad');
              }
            } else {
              console.log('No Cedula')
              this.alerta.excelErr('Cedula');
            }
          } else {
            console.log('No Apellidos')
            this.alerta.excelErr('Apellidos');
          }
      }
      fileReader.readAsArrayBuffer(this.file);
}

  RegistrarMedidores(){

    let query = 'INSERT INTO db_liwa.Inicial(Apellidos, Cedula, Comunidad, Departamento, Latitud, Longitud, Municipio, Nombres, Vereda, Id_Seccional, Fecha, Id) VALUES ';
    if(this.Registrar == true) {
      for(let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
        query = query + "('" + this.MEDIDORESEXCEL[i]['Apellidos'] +"','" + this.MEDIDORESEXCEL[i]['Cedula'] + "','" + this.MEDIDORESEXCEL[i]['Comunidad'] + "','" + this.MEDIDORESEXCEL[i]['Departamento'] + "','" + this.MEDIDORESEXCEL[i]['Latitud'] + "','" + this.MEDIDORESEXCEL[i]['Longitud'] + "','" + this.MEDIDORESEXCEL[i]['Municipio'] + "','" + this.MEDIDORESEXCEL[i]['Nombres'] + "','" + this.MEDIDORESEXCEL[i]['Vereda'] + "','" + this.global.idSeccional+ "', '"+ moment(new Date()).format("YYYY-MM-DD HH:mm:ss") +"', '"+ this.global.idSeccional + "-" + i + "-" + moment.now() +"'),";
      }
      // setTimeout(() => {
        var queryDEF = query.substring(0, query.length - 1);
        queryDEF = queryDEF + ';'
        console.log(queryDEF);
        const pdata3 = { option: 'insertar3', texto: queryDEF };
        this.global.consultar(pdata3, async (err3, response3) => {
          console.log(response3, queryDEF);
          if (err3 == null && response3 == true) {
            this.alerta.closeValid();
            this.ngOnInit();
            this.MEDIDORESEXCEL = new Array();
            this.Registrar = false;
            this.Subir = false;
          } else {
            console.log(err3);
          }
      });
      // }, 500);
    } else {

    }
  }


  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    this.ngOnInit();
  }

  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el registro?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        this.ngOnInit();
      }
    });
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Inicial SET IsDelete = '1' WHERE Id ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Registro eliminado correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);

  }


}
