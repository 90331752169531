import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Swiper, Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import * as $ from 'jquery';
import { GlobalAlertService } from 'src/app/global-alert.service';
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-replanteo-form',
  templateUrl: './replanteo-form.component.html',
  styleUrls: ['./replanteo-form.scss']
})
export class ReplanteoFormComponent implements OnInit {
  clientClass;
  photosForm;
  photosFirmas=Array();
  openFlag: boolean = false;
  call: boolean = false;
  call1: boolean = true;
  call2: boolean = true;
  call3: boolean = true;
  call4: boolean = true;
  call5: boolean = true;
  call6: boolean = true;
  call7: boolean = true;
  call8: boolean = true;
  call9: boolean = true;
  call10: boolean = true;
  call11: boolean = true;
  edit: boolean = false;
  edit1: boolean = false;
  edit2: boolean = false;
  edit3: boolean = false;
  edit4: boolean = false;
  edit5: boolean = false;
  edit6: boolean = false;
  edit7: boolean = false;
  edit8: boolean = false;
  whatsappProvider: boolean = false;
  replanteoData: any[] = [];
  departamentos: any[] = [];
  municipios: any[] = [];
  srcImg: any[] = [];
  srcImgid: any[] = [];
  Photo : string = '/';
  totalImages: number;
  srcImgViuw: string[] = [];
  fotoUrlid;
  evidence;
  ext;
  formData;
  fotoUrl:any[] =[];
  Photoid;
  evidenceId;
  departamento = "Seleccione departamento...";
  municipio: any[] = [];
  texto = "Seleccione departamento...";
  texto1 = "Seleccione municipio...";
  detailsOn: boolean = false;
  //bd variables
  Fecha;
  Nombre_Completo: string;
  TipoDoc: string;
  Cedula: number;
  Telefono: number;
  Sexo: string;
  banderaExtencion:boolean=false;
  Correo: string;
  Ocupacion: string;
  Depto = "Seleccione departamento...";
  Municipio = "Seleccione municipio...";
  Cod_Mun;
  Vereda;
  Latitud;
  Longitud;
  Altitud;
  Tipo;
  Uso_Predio;
  Posesion;
  Estrato;
  Energia;
  // CONDING NOW
  Medidor;
  MarcaMedidor;
  SerialMedidor;
  Fotovoltaico;
  Gabinete;
  Paneles;
  PuestaTierraSPT;
  EstadoTarjetaNFC
  Inversor;
  ControladorCargaMPPT;
  Bateria;
  Protecciones;
  Soportes;
  TarjetaNFC;

  TipoGabinete;
  Acometida;
  Tensor;
  CajaMedidor;
  SPT;
  Percepcion;
  TelefonoIn;
  log:boolean = false;
  Lavadora;
  Nevera;
  Licuadora;
  Ventilador;
  AireAcondicionado;
  Sanduchera;
  NumLED;
  NumIncand;
  TV;
  VoluntadPago;
  Tarifa;
  // CODING NOW
  Celulares;
  Internet;
  DuchaElectrica;
  CercasElectricas;
  OtrosElectrodomesticos;

  idEncuestador;
  idProyecto;
  constructor(public global: GlobalService,
    private route: Router,
    private spinner: NgxSpinnerService,
    public ruta: Router,
    private alert: GlobalAlertService) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.global.idBenefit == undefined) {
      this.route.navigateByUrl('/replanteo');
      this.spinner.hide();
    } else {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      // setTimeout(() => {
        this.global.returnProvinces().subscribe((response) => {
          console.log(response, 'miconsole')
          let deptos: any[] = [];
          this.municipio = response;
          for (let i = 0; i < response.length; i++) {
            deptos.push(response[i].departamento);
          }
          this.departamentos = deptos.filter((element, index) => {
            return deptos.indexOf(element) === index;
          });
        });
        const pdata = { option: 'replanteo-form', idencuesta: this.global.idBenefit };
        // console.log(pdata);
        this.global.consultar(pdata, async (err, response) => {
          this.replanteoData = await response;
          this.idProyecto = this.replanteoData[0][3];
          this.idEncuestador = this.replanteoData[0][2];
          this.Fecha = this.replanteoData[0][5];
          this.Nombre_Completo = this.replanteoData[0][6];
          this.TipoDoc = this.replanteoData[0][7];
          this.Cedula = this.replanteoData[0][8];
          this.Telefono = this.replanteoData[0][9];
          this.Sexo = this.replanteoData[0][10];
          this.Correo = this.replanteoData[0][11];
          this.Ocupacion = this.replanteoData[0][12];
          this.Depto = this.replanteoData[0][13];
          this.Municipio = this.replanteoData[0][14];
          this.Cod_Mun = this.replanteoData[0][15];
          this.Vereda = this.replanteoData[0][16];
          this.Latitud = this.replanteoData[0][17];
          this.Longitud = this.replanteoData[0][18];
          this.Altitud = this.replanteoData[0][19];
          this.Tipo = this.replanteoData[0][20];
          this.Uso_Predio = this.replanteoData[0][21];
          this.Posesion = this.replanteoData[0][22];
          this.Estrato = this.replanteoData[0][23];
          // antes de la mano de luismi
          this.Energia = this.replanteoData[0][24];
          // CONDING NOW
          // Medidor;
          // MarcaMedidor;
          // SerialMedidor;
          // Fotovoltaico;
          // Gabinete;
          // Paneles;
          // PuestaTierraSPT;
          // EstadoTarjetaNFC
          // Inversor;
          // ControladorCargaMPPT;
          // Bateria;
          // Protecciones;
          // Soportes;
          // TarjetaNFC
          this.Medidor = this.replanteoData[0][24];
          this.MarcaMedidor = this.replanteoData[0][25];
          this.SerialMedidor = this.replanteoData[0][26];
          this.Fotovoltaico = this.replanteoData[0][27];
          this.Gabinete = this.replanteoData[0][28];
          this.Paneles= this.replanteoData[0][29];
          this.PuestaTierraSPT = this.replanteoData[0][30];
          this.EstadoTarjetaNFC = this.replanteoData[0][31];
          this.Inversor = this.replanteoData[0][32];
          this.ControladorCargaMPPT = this.replanteoData[0][33];
          this.Bateria = this.replanteoData[0][34];
          this.Protecciones = this.replanteoData[0][35];

          this.Soportes = this.replanteoData[0][37];
          this.TarjetaNFC = this.replanteoData[0][38];

          // this.Gabinete = this.replanteoData[0][28];
          this.TipoGabinete = this.replanteoData[0][26];
          this.Acometida = this.replanteoData[0][27];
          this.Tensor = this.replanteoData[0][28];
          this.CajaMedidor = this.replanteoData[0][29];
          this.SPT = this.replanteoData[0][30];
          this.Percepcion = this.replanteoData[0][39];


          // this.TelefonoIn = this.replanteoData[0][32];
          this.TelefonoIn = this.replanteoData[0][40];
          this.Celulares = this.replanteoData[0][41]
          this.Internet = this.replanteoData[0][42];
          this.Lavadora = this.replanteoData[0][43];
          this.Nevera = this.replanteoData[0][44];
          this.Licuadora = this.replanteoData[0][45];
          this.Ventilador = this.replanteoData[0][46];
          this.AireAcondicionado = this.replanteoData[0][47];
          this.Sanduchera = this.replanteoData[0][48];
          this.DuchaElectrica = this.replanteoData[0][49];
          this.CercasElectricas = this.replanteoData[0][50]
          // ducha
          // cercas electricas
          this.NumLED = this.replanteoData[0][51];
          this.NumIncand = this.replanteoData[0][52];
          this.TV = this.replanteoData[0][53];
          // console.log('este es el mio',this.replanteoData[0][54])
          this.OtrosElectrodomesticos = this.replanteoData[0][54];
          // otros
          // this.VoluntadPago = this.replanteoData[0][43];
          this.VoluntadPago = this.replanteoData[0][55];
          // this.Tarifa = this.replanteoData[0][44];
          this.Tarifa = this.replanteoData[0][56];
          this.searchMunicipalities(this.Depto);
          const pdata1 = { option: 'replanteo-fotos', idencuesta: this.global.idBenefit };
          console.log(this.global.idBenefit, '<---id beneficiario')
          this.global.consultar(pdata1, async (err1, response1) => {
            this.photosForm = await response1;
            console.log(this.photosForm, 'fotos de la instalacion');
            this.openFlag = true;
            const pdataF = { option: 'insertar2', texto:`SELECT rutaserver, Id_Foto_Firma FROM db_liwa.fotos_firma WHERE Id_Formulario = '${this.global.idBenefit}' AND IsDelete = 0;`  };
            this.global.consultar(pdataF, async (err1, response1) => {
              // console.log(response1, "estetetetttetete")
              this.photosFirmas = await response1
              this.spinner.hide();
            })
          });
        });
      // }, 600);
    }
  }

  searchMunicipalities(depto) {
    this.spinner.show();
    let muni: any[] = [];
    // setTimeout(() => {
      for (let i = 0; i < this.municipio.length; i++) {
        if (depto == this.municipio[i].departamento) {
          muni.push(this.municipio[i].municipio);
        } else {
          continue;
        }
      }
      this.municipios = muni;
      this.spinner.hide();
    // }, 600);
  }
  volver() {
    this.ruta.navigateByUrl("/replanteo");
  }
  editModule(numb: number) {
    switch (numb) {
      case 0:
        this.edit = !this.edit;
        break;
      case 1:
        this.edit1 = !this.edit1;
        break;
      case 2:
        this.edit2 = !this.edit2;
        break;
      case 3:
        this.edit3 = !this.edit3;
        break;
      case 4:
        this.edit4 = !this.edit4;
        break;
      case 5:
        this.edit5 = !this.edit5;
        break;
      case 6:
        this.edit6 = !this.edit6;
        break;
      case 7:
        this.edit7 = !this.edit7;
        break;

      default:
        break;
    }
  }

  animatedTab(numbr: number) {
    switch (numbr) {
      case 1:
        this.call = !this.call;
        break;
      case 2:
        this.call1 = !this.call1;
        break;
      case 3:
        this.call2 = !this.call2;
        break;
      case 4:
        this.call3 = !this.call3;
        break;
      case 5:
        this.call4 = !this.call4;
        break;
      case 6:
        this.call5 = !this.call5;
        break;
      case 7:
        this.call6 = !this.call6;
        break;
      case 8:
        this.call7 = !this.call7;
        break;
      case 9:
        this.call8 = !this.call8;
        break;
      case 10:
        this.call9 = !this.call9;
        break;
      case 11:
        this.call10 = !this.call10;
        break;
        case 12:
          this.call11 = !this.call11;
          break;

      default:
        break;
    }
  }

  // exploreImg() {
  //   let elem: HTMLElement = document.querySelector('#exPhoto');
  //   elem.click();
  // }
  // onFileSelected(event) {
  //   const selectedFiles = event.target.files;
  //   console.log(selectedFiles, 'entamos melitos')
  //  let algo =  selectedFiles[0].size;
  //    selectedFiles[0].name.slice(0, 8) === "WhatsApp" ? this.whatsappProvider = true : this.whatsappProvider = false
  //   algo <= 690 ? this.log = false : this.log = true
  //   this.log = true
  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       const imagen = new Image();
  //       imagen.src = event.target.result;
  //       this.srcImg[i] = imagen.src;
  //         for (let e = 0; e < this.srcImg.length; e++) {
  //           // ESTE FOR RECORRE TODA LA RUTA TEMPORATEA PARA ASIGNAR UNA EXTENCION
  //           this.Photo = this.srcImg[e];
  //           //  SOLO SE REQUIERE QUE ENTRE A LEER LA EXTENCION DE LA IMAGEN UNA SOLA VES
  //           if ( i === e ) this.validateExt(this.Photo?.split('/')[1]) , console.log('este')

  //         }
  //     };
  //     reader.readAsDataURL(event.target.files[i]);
  //   }
  //   this.evidence = true;
  // }
  // onFileSelected(event) {
  //   const selectedFiles = event.target.files;
  //   let algo =  selectedFiles[0].size;
  //   console.log(algo, 'entamos melitos')
  //    selectedFiles[0].name.slice(0, 8) === "WhatsApp" ? this.whatsappProvider = true : this.whatsappProvider = false
  //   algo <= 1000 ? this.log = false : this.log = true
  //   // this.log = true
  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       let imagen = new Image();
  //       imagen.src = event.target.result;
  //       this.srcImg[i] = imagen.src;
  //         for (let e = 0; e < this.srcImg.length; e++) {
  //           // ESTE FOR RECORRE TODA LA RUTA TEMPORATEA PARA ASIGNAR UNA EXTENCION
  //           this.Photo = this.srcImg[e];
  //           console.log('Foto',this.Photo)
  //           //  SOLO SE REQUIERE QUE ENTRE A LEER LA EXTENCION DE LA IMAGEN UNA SOLA VES
  //           if ( i === e ) this.validateExt(this.Photo.split('/')[1]) , console.log('este')

  //         }
  //     };
  //     reader.readAsDataURL(event.target.files[i]);
  //   }
  //   this.evidence = true;
  // }


  // validateExt(file: any) {
  //   let extension = file.split(';')[0];
  //   console.log("dentro del valexte", extension)
  //   extension === "png" ? this.banderaExtencion = true : this.banderaExtencion = false
  //   this.ext = '.' + extension;
  // }
  // loadImg() {
  //   let idProjectFunc = this.idProyecto;
  //   if (this.evidence === false || this.log === false ) {
  //     this.alert.descErr('Error al subir la foto, verifique nuevamente');
  //     this.spinner.hide()
  //   } else {
  //     this.spinner.show();
  //     try {
  //       // setTimeout(() => {
  //         let con1 = 0;
  //         let con2 = 0;
  //         for (let i = 0; i < this.srcImg.length; i++) {
  //           var resizedBase64
  //           // console.log('testettetete', this.srcImg[0])
  //           // this.compressImage(this.srcImg[i], 880, 700).then(compressed => {
  //           //     resizedBase64 = compressed;
  //               con1++;
  //               console.log('Foto #' + i, this.srcImg[i]);
  //               const FOTOOSINCOMPRIMIR = this.srcImg[i].slice(22, this.srcImg[i].length);
  //               // const FOTOOCOMPRIMIDA = resizedBase64.slice(22, this.srcImg[i].length);
  //               const imageName = i + '-' + moment().unix() + this.ext;
  //               console.log(FOTOOSINCOMPRIMIR, 'a1212');
  //               const imageFile = FOTOOSINCOMPRIMIR  ;
  //               // const imageFile = FOTOO2;
  //               const data = new FormData();
  //               data.append('image', imageFile);
  //               data.append('nombre', imageName);
  //               data.append('ruta', idProjectFunc + '-' + moment());
  //               console.log(data,'lo que se le envia al ajax')
  //               $.ajax({
  //                 url: 'https://www.php.engenius.com.co/FOT/subir_foto_AOM.php',
  //                 type: 'post',
  //                 dataType: 'json',
  //                 data,
  //                 processData: false,
  //                 contentType: false
  //               }).then((data1) => {
  //                 console.log('imp54 O.O', data1);
  //                 this.fotoUrl[i] = data1;
  //                 con2++;
  //                 if (this.fotoUrl[i] == 'BADEXT' || this.fotoUrl[i] == null || this.fotoUrl[i] == undefined || this.fotoUrl[i] == '' || this.fotoUrl[i] == 'NULL') {
  //                   this.alert.descErr('Error al subir la foto, verifique nuevamente');
  //                 } else {
  //                   // # of Photos validator
  //                   let idFotoEnc = idProjectFunc + '-' + i + '-' + moment();
  //                   const query = 'REPLACE INTO db_liwa.Fotos_AOM (Id_Foto_AOM, Id_Encuesta, Id_Encuestador, Rutalocal, Rutaserver, Estado, Fecha, upload, edit, IsDelete)' +
  //                     ' VALUES (\'' +
  //                     idFotoEnc + '\',\'' +
  //                     this.global.idBenefit + '\',\'' +
  //                     this.idEncuestador + '\',\'' +
  //                     '-' + '\',\'' +
  //                     data1 + '\',\'' +
  //                     'uploaded' + '\',\'' +
  //                     moment().format('YYYY-MM-DD HH:mm:ss') + '\',\'' +
  //                     1 + '\',\'' +
  //                     0 + '\',\'' +
  //                     0 + '\');';
  //                   const pdata1 = { option: 'insertar3', texto: query };
  //                   this.global.consultar(pdata1, async (err, response) => {
  //                     // console.log(response, query);
  //                     if ( await err == null && await response == true) {
  //                       // this.alert.descValid('Imagenes subidas correcamente');
  //                     } else {
  //                       this.alert.descErr('¡Error al subir imagenes! ');
  //                     }
  //                   });
  //                   if (con1 == con2 && con2 == this.srcImg.length) {
  //                     // this.ngOnInit();
  //                     this.srcImg = [];
  //                     this.Photo = '/';
  //                     this.evidence = false;
  //                     this.spinner.hide();
  //                     this.alert.descValid('Imagenes subidas correctamente');
  //                     const pdata1 = { option: 'replanteo-fotos', idencuesta: this.global.idBenefit };
  //                           // console.log(this.global.idBenefit, '<---id beneficiario')
  //                           this.global.consultar(pdata1, async (err1, response1) => {
  //                             this.photosForm = await response1;
  //                           })
  //                           this.ngOnInit()
  //                   }
  //                 }
  //               });
  //           // })

  //         }
  //       // }, 400);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }


  // }


  exploreImg(): void {
    const inputElement: HTMLInputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.multiple = true; // Permitir la selección de múltiples archivos
    inputElement.addEventListener('change', this.onFilesSelected.bind(this));
    inputElement.click();
  }

  validExt(filename: string): void {
    const extension = filename.split('.').pop().toLowerCase();
    this.ext = '.' + extension;
  }

  onFilesSelected(event: Event): void {
    const inputElement: HTMLInputElement = <HTMLInputElement>event.target;
    if (inputElement.files && inputElement.files.length > 0) {
      this.totalImages = inputElement.files.length;
      let loadedImages = 0;

      // Iterar a través de los archivos seleccionados
      for (let i = 0; i < inputElement.files.length; i++) {
        const file: File = inputElement.files[i];
        this.validExt(file.name);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // Aquí puedes manejar cada imagen individualmente
          // this.srcImg = e.target.result;
          this.srcImgViuw.push(e.target.result);
          console.log("Ver Fotos:", this.srcImgViuw);
          this.evidence = true;
          loadedImages++;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  loadImg(): void {
    if (!this.evidence) {
      console.error('Error: No se ha seleccionado ninguna imagen.');
      this.alert.descErr('Es necesario que por favor seleccione alguna imagen');
      this.spinner.hide();
      // Agrega aquí tu lógica para mostrar un mensaje de error si no se selecciona ninguna imagen
      return;
    } else {
      this.spinner.show();

      for (let index = 0; index < this.totalImages; index++) {
        // Aquí puedes acceder al índice actual con la variable 'index'
        let idProjectFunc = this.idProyecto;

        const imageName = this.idEncuestador + '-' + index + '-' + moment().unix() + this.ext;
        const idfoto = this.idEncuestador + '-' + index + '-' + moment().unix();
        const formData: FormData = new FormData();
        formData.append('image', this.dataURItoBlob(this.srcImgViuw[index]), imageName);
        formData.append('nombre', imageName);
        formData.append('ruta', idProjectFunc + "-" + index + "-" + moment());

        // Resto del código
        fetch('https://www.php.engenius.com.co/FOT/subir_foto_AOM.php', {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            if (data === 'BADEXT' || data == null || data == undefined || data == '' || data == 'NULL') {
              console.error('Error: Extensión de archivo no permitida.');
              this.alert.descErr('Error al subir la foto, verifique nuevamente');
              this.spinner.hide();
              return
            }
            console.log('Ruta de la imagen guardada:', data);

            let idFotoEnc = idProjectFunc + "-" + index + "-" + moment().unix();
            const query =
              "REPLACE INTO db_liwa.Fotos_AOM (Id_Foto_AOM, Id_Encuesta, Id_Encuestador, Rutalocal, Rutaserver, Estado, Fecha, upload, edit, IsDelete)" +
              " VALUES ('" +
              idFotoEnc +
              "','" +
              this.global.idBenefit +
              "','" +
              this.idEncuestador +
              "','" +
              "-" +
              "','" +
              data +
              "','" +
              "uploaded" +
              "','" +
              moment().format("YYYY-MM-DD HH:mm:ss") +
              "','" +
              1 +
              "','" +
              0 +
              "','" +
              0 +
              "');";
            const pdata1 = { option: 'insertar', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              console.log(response, query);
              if (response) {
                if (index === this.totalImages - 1) {
                  // Realizar acciones después de cargar todas las imágenes
                  this.spinner.hide();
                  this.alert.descValid("Fotos Agregadas correctamente!");
                  this.srcImgViuw = []; // Limpia el arreglo de imágenes
                  this.evidence = false;
                  this.ngOnInit();
                }
              } else if (response == null || response == undefined) {
                this.spinner.hide();
                this.alert.descErr('Error, verifique nuevamente que no hayan campos vacíos');
                data = undefined;
                this.evidence = false;
                this.ngOnInit();
              }
            });
          })
          .catch(error => {
            console.error('Error al subir la imagen:', error);
            this.alert.descErr('Error al subir la foto, verifique nuevamente o comuníquese con soporte');
            this.spinner.hide();
          });

      }
    }
  }


  loadImgFirmas(): void {
    if (!this.evidence) {
      console.error('Error: No se ha seleccionado ninguna imagen.');
      this.alert.descErr('Es necesario que por favor seleccione alguna imagen');
      this.spinner.hide();
      // Agrega aquí tu lógica para mostrar un mensaje de error si no se selecciona ninguna imagen
      return;
    } else {
      this.spinner.show();

      for (let index = 0; index < this.totalImages; index++) {
        // Aquí puedes acceder al índice actual con la variable 'index'
        let idProjectFunc = this.idProyecto;

        const imageName = this.idEncuestador + '-' + index + '-' + moment().unix() + this.ext;
        const idfoto = this.idEncuestador + '-' + index + '-' + moment().unix();
        const formData: FormData = new FormData();
        formData.append('image', this.dataURItoBlob(this.srcImgViuw[index]), imageName);
        formData.append('nombre', imageName);
        formData.append('ruta', idProjectFunc + "-" + index + "-" + moment());

        // Resto del código
        fetch('https://www.php.engenius.com.co/subir_foto_firma_LIWA.php', {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            if (data === 'BADEXT' || data == null || data == undefined || data == '' || data == 'NULL') {
              console.error('Error: Extensión de archivo no permitida.');
              this.alert.descErr('Error al subir la foto, verifique nuevamente');
              this.spinner.hide();
              return
            }
            console.log('Ruta de la imagen guardada:', data);

            let idFotoEnc = idProjectFunc + "-" + index + "-" + moment().unix();
            const query =
                "REPLACE INTO db_liwa.fotos_firma (Id_Foto_Firma, Id_Formulario, Rutalocal, Rutaserver, Estado, Fecha, upload, edit, IsDelete)" +
                " VALUES ('" +
                idFotoEnc +
                "','" +
                this.global.idBenefit +
                "','" +
                // this.idEncuestador + '\',\'' +
                "-" +
                "','" +
                data +
                "','" +
                "uploaded" +
                "','" +
                moment().format("YYYY-MM-DD HH:mm:ss") +
                "','" +
                1 +
                "','" +
                0 +
                "','" +
                0 +
                "');";
            const pdata1 = { option: 'insertar', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              console.log(response, query);
              if (response) {
                if (index === this.totalImages - 1) {
                  // Realizar acciones después de cargar todas las imágenes
                  this.spinner.hide();
                  this.alert.descValid("Fotos Agregadas correctamente!");
                  this.srcImgViuw = []; // Limpia el arreglo de imágenes
                  this.evidence = false;
                  this.ngOnInit();
                }
              } else if (response == null || response == undefined) {
                this.spinner.hide();
                this.alert.descErr('Error, verifique nuevamente que no hayan campos vacíos');
                data = undefined;
                this.evidence = false;
                this.ngOnInit();
              }
            });
          })
          .catch(error => {
            console.error('Error al subir la imagen:', error);
            this.alert.descErr('Error al subir la foto, verifique nuevamente o comuníquese con soporte');
            this.spinner.hide();
          });

      }
    }
  }



  confirmEdit(num: number) {
    switch (num) {
      case 1:
        if (this.Fecha == undefined || this.Nombre_Completo == undefined || this.TipoDoc == undefined || this.Cedula == undefined ||
          this.Telefono == undefined || this.Sexo == undefined || this.Correo == undefined ||
          this.Ocupacion == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Fecha = ' + '\'' + this.Fecha + '\','
                + 'Nombre_Completo = ' + '\'' + this.Nombre_Completo + '\','
                + 'TipoDoc = ' + '\'' + this.TipoDoc + '\','
                + 'Cedula = ' + '\'' + this.Cedula + '\','
                + 'Telefono = ' + '\'' + this.Telefono + '\','
                + 'Sexo = ' + '\'' + this.Sexo + '\','
                + 'Correo = ' + '\'' + this.Correo + '\','
                + 'Ocupacion = ' + '\'' + this.Ocupacion + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1, async (err, response) => {
                // console.log(response, query);
                if (await err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }
        break;
      case 2:
        if (this.Depto == undefined || this.Municipio == undefined || this.Cod_Mun == undefined ||
          this.Vereda == undefined || this.Latitud == undefined || this.Longitud == undefined ||
          this.Altitud == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit1 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Depto = ' + '\'' + this.Depto + '\','
                + 'Municipio = ' + '\'' + this.Municipio + '\','
                + 'Cod_Mun = ' + '\'' + this.Cod_Mun + '\','
                + 'Cedula = ' + '\'' + this.Cedula + '\','
                + 'Vereda = ' + '\'' + this.Vereda + '\','
                + 'Latitud = ' + '\'' + this.Latitud + '\','
                + 'Longitud = ' + '\'' + this.Longitud + '\','
                + 'Altitud = ' + '\'' + this.Altitud + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1, async (err, response) => {
                // console.log(response, query);
                if (await err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit1 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit1 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }


        break;
      case 3:
        if (this.Tipo == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit2 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Tipo = ' + '\'' + this.Tipo + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1, (err, response) => {
                // console.log(response, query);
                if (err == null && response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit2 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit2 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }
        break;
      case 4:
        if (this.Uso_Predio == undefined || this.Posesion == undefined || this.Estrato == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit3 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Uso_Predio = ' + '\'' + this.Uso_Predio + '\','
                + 'Posecion = ' + '\'' + this.Posesion + '\','
                + 'Estrato = ' + '\'' + this.Estrato + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1,async (err, response) => {
                // console.log(response, query);
                if ( await err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit3 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit3 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }
        break;
      case 5:
        if (
          this.EstadoTarjetaNFC == undefined ||  this.Inversor == undefined ||this.ControladorCargaMPPT == undefined ||
          this.Bateria == undefined || this.Protecciones == undefined ||  this.MarcaMedidor == undefined ||
          this.Soportes == undefined || this.TarjetaNFC == undefined || this.Medidor == undefined || this.Gabinete == undefined ||
          this.SerialMedidor == undefined || this.Fotovoltaico == undefined || this.Paneles == undefined ||
          this.SPT == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit4 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            // console.log('se ejecuto lo inesperado')
            try {
              const query = 'UPDATE db_liwa.AOM SET '
              + 'Medidor = ' + '\'' + this.Medidor + '\','
              + 'MarcaM = ' + '\'' + this.MarcaMedidor + '\','
              + 'SerialM = ' + '\'' + this.SerialMedidor + '\','
              + 'Fotovoltaico = ' + '\'' + this.Fotovoltaico + '\','
              + 'Gabinete = ' + '\'' + this.Gabinete + '\','
              + 'Paneles =' + '\'' + this.Paneles + '\','
              + 'SPT = ' + '\'' + this.PuestaTierraSPT + '\','
              + 'PortaT = ' + '\'' + this.EstadoTarjetaNFC + '\','
              + 'Inversor = ' + '\'' + this.Inversor + '\','
              + 'MPPT = ' + '\'' + this.ControladorCargaMPPT + '\','
              + 'Bateria = ' + '\'' + this.Bateria + '\','
              + 'Protecciones = ' + '\'' + this.Protecciones + '\','
              + 'Soporte = ' + '\'' + this.Soportes + '\','
              + 'NFC = ' + '\'' + this.TarjetaNFC + '\''
              + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1, async (err, response) => {
                // console.log(response, query);
                if (await err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit4 = false;
                  this.spinner.hide();
                } else {
                  // console.log( 'est es ',response,err, )
                  this.alert.descErr();
                  this.edit4 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }
        break;
      case 6:
        if (this.Uso_Predio == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit5 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Percepcion = ' + '\'' + this.Percepcion + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              console.log(pdata1);
              this.global.consultar(pdata1, (err, response) => {
                console.log(response, query);
                if (err == null && response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit5 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit5 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }

        break;
      case 7:
        if (this.CercasElectricas == undefined || this.OtrosElectrodomesticos == undefined ||
           this.TelefonoIn == undefined || this.Internet == undefined || this.Lavadora == undefined ||
          this.Nevera == undefined || this.Licuadora == undefined || this.Ventilador == undefined ||
          this.AireAcondicionado == undefined || this.Sanduchera == undefined || this.NumLED == undefined ||
          this.NumIncand == undefined || this.TV == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit6 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'Otras = ' + '\'' + this.OtrosElectrodomesticos+ '\','
                + 'Cercas = ' + '\'' + this.CercasElectricas + '\','
                + 'TelefonoIn = ' + '\'' + this.TelefonoIn + '\','
                + 'Internet = ' + '\'' + this.Internet + '\','
                + 'Lavadora = ' + '\'' + this.Lavadora + '\','
                + 'Nevera = ' + '\'' + this.Nevera + '\','
                + 'Licuadora = ' + '\'' + this.Licuadora + '\','
                + 'Ventilador = ' + '\'' + this.Ventilador + '\','
                + 'AireAcondicionado = ' + '\'' + this.AireAcondicionado + '\','
                + 'Sanduchera = ' + '\'' + this.Sanduchera + '\','
                + 'NumLED = ' + '\'' + this.NumLED + '\','
                + 'NumIncand = ' + '\'' + this.NumIncand + '\','
                + 'TV = ' + '\'' + this.TV + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              console.log(pdata1);
              this.global.consultar(pdata1, async (err, response) => {
                // console.log(response, query);
                if (await err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit6 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit6 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }

        break;
      case 8:
        if (this.VoluntadPago == undefined || this.Tarifa == undefined) {
          this.alert.descErr('Verifique que no hayan campos vacíos');
          this.edit7 = false;
        } else {
          this.spinner.show();
          // setTimeout(() => {
            try {
              const query = 'UPDATE db_liwa.AOM SET '
                + 'VoluntadPago = ' + '\'' + this.VoluntadPago + '\','
                + 'Tarifa = ' + '\'' + this.Tarifa + '\''
                + ' WHERE Id_Encuesta =' + '\'' + this.global.idBenefit + '\';';
              const pdata1 = { option: 'insertar3', texto: query };
              // console.log(pdata1);
              this.global.consultar(pdata1, async (err, response) => {
                // console.log(response, query);
                if (err == null && await response == true) {
                  this.alert.descValid('Los datos se actualizaron con éxito!');
                  this.ngOnInit();
                  this.edit7 = false;
                  this.spinner.hide();
                } else {
                  this.alert.descErr();
                  this.edit7 = false;
                  this.spinner.hide();
                }
              });
            } catch (e) {
              console.log(e);
            }
          // }, 600);
        }
        break;

      default:
        break;
    }
  }
  deleteBtn() {
    Swal.fire({
      title: '<strong>¿Está segur@ que desea eliminar el formulario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        // setTimeout(() => {
          try {
            var query = `UPDATE db_liwa.PorcentajeAOM SET IsDelete = '1' WHERE Id_Encuesta ='${this.global.idBenefit}';`
            const pdata1 = { option: 'insertar3', texto: query };
            this.global.consultar(pdata1, (err, response) => {
              if (err == null && response == true) {
                this.alert.descValid('Formulario eliminado correctamente');
                this.route.navigateByUrl('/replanteo');
                this.spinner.hide();
              }
            });
          } catch (error) {
            console.log(error);
          }
        // }, 1000);
      } else {
        // this.ngOnInit();
      }
    });
  }
  approveBtn() {
    Swal.fire({
      title: '<strong>¿Está segur@ que desea aprobar la revisión del formulario?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        // setTimeout(() => {
          try {
            var query = `UPDATE db_liwa.PorcentajeAOM SET IsVerifi = '1' WHERE Id_Encuesta ='${this.global.idBenefit}';`
            const pdata1 = { option: 'insertar3', texto: query };
            // console.log(pdata1);
            this.global.consultar(pdata1, (err, response) => {
              // console.log(response, query);
              if (err == null && response == true) {
                this.alert.descValid('Formulario aprobado correctamente');
                this.route.navigateByUrl('/replanteo');
                this.spinner.hide();
              } else {
                this.alert.descErr();
                this.ngOnInit();
                this.spinner.hide();
              }
            });
          } catch (e) {
            console.log(e);
          }
        // }, 600);
      } else {
        // this.ngOnInit();
      }
    });

  }
  eliminarFoto(data) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar la foto?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      console.log(data , 'esta es la data de la foto')
      if (result.isConfirmed) {
        const query = 'UPDATE db_liwa.Fotos_AOM SET IsDelete = 1 WHERE Id_Foto_AOM  =\'' + data + '\' ;';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          if ( await err == null && await response == true) {
            this.alert.descValid('Foto eliminada correctamente');
            this.ngOnInit();
          }else {
            this.alert.descErr('Oops, hubo un error. Verifique nuevamente');
            this.ngOnInit();
          }
          });
      } else {
        // this.ngOnInit();
      }
    });
  }

  eliminarFotoFirma(data) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar la foto?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      console.log(data , 'esta es la data de la foto')
      if (result.isConfirmed) {
        const query = 'UPDATE db_liwa.fotos_firma SET IsDelete = 1 WHERE Id_Foto_Firma  =\'' + data + '\' ;';
        const pdata1 = { option: 'insertar3', texto: query };
        this.global.consultar(pdata1, async (err, response) => {
          if ( await err == null && await response == true) {
            this.alert.descValid('Foto eliminada correctamente');
            this.ngOnInit();
          }else {
            this.alert.descErr('Oops, hubo un error. Verifique nuevamente');
            this.ngOnInit();
          }
          });
      } else {
        // this.ngOnInit();
      }
    });
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

}
