import { Component, OnInit } from "@angular/core";
import { GlobalAlertService } from "src/app/global-alert.service";
import { GlobalService } from "../../global.service";
import { Router } from "@angular/router";
import * as $ from "jquery";
import * as moment from "moment";
import Swal from "sweetalert2";
import { ExcelService } from "src/app/excel.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";

@Component({
  selector: "app-user-ticket",
  templateUrl: "./user-ticket.component.html",
})
export class UserTicketComponent implements OnInit {
  USERDATALIWA;
  tickets = new Array();
  idTicket;
  Tables;
  clientClass;
  table;
  Toexcel = new Array();
  infoVisitaTecnica = new Array();
  banderaRool: boolean = false;
  arrayBuffer: any;
  file: File;
  MEDIDORESEXCEL;
  Registrar = false;
  fechaI: String;
  fechaF: String;
  idCliente;
  descripcion: String;
  email = [
    'camilo.mendoza@sunpoweresp.co',
    'juancamilomendozavillegas14@gmail.com',
  ];

  constructor(
    public global: GlobalService,
    public modalService: NgbModal,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService
  ) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.spinner.show();
    console.log( this.global.Id_Client, "<- id cliente")
    this.idCliente = this.global.Id_Client;
    this.global.Id_Ticket = "";
      this.global.idEdit = undefined;
      this.clientClass = this.global.CLIENTDATALIWA[8];
      if (this.global.idRole == 15) this.listadoTickets(1);
      else this.listadoTickets(2);

  }

  enviarcorreoCreado(infoCorreo, idTk) {
    setTimeout(() => {
      const pdata9 = {
        option: "mail",
        ticket: idTk,
        Seccional: "hg",
        descr: infoCorreo[8],
        type: 1,
        contacto: infoCorreo[11],
        tel: infoCorreo[10],
        user: this.global.UserName,
        municipio: infoCorreo,
      };
      this.global.consultar(pdata9, (err2, response2) => {
        // console.log('Datos correo', response2);
      });
    }, 500);
  }
  eviarSMS(infoSMS, idTk) {
    setTimeout(() => {
      const pdata9 = {
        number: "57" + infoSMS[10],
        message: `se acaba de agregar un ticket de tipo : ${infoSMS[4]}, relizada el dia ${infoSMS[12]} con id Ticket :${idTk},cargado de manera masiva por : ${this.global.UserName}`,
        type: "1",
      };
      this.global.consultarSMS(pdata9, (err2, response2) => {
        // console.log('Datos correo', response2);
      });
    }, 500);
  }

  async listadoTickets(tipoLista: number) {
    let pdata9;
    const pdataO =
      tipoLista === 1
        ? (pdata9 = {
          option: "insertar2", texto: `SELECT T.Id_Tickets,(db_liwa.getdays(Creation_Date, NOW())) AS Semaforo,
        (SELECT S.Name_Status FROM db_liwa.Ticket_Statuses S WHERE S.Id_Statuses=T.Id_Statuses)AS Estado,
        (SELECT P.Nombre_Completo FROM db_liwa.AOM P WHERE P.Id=T.Id_Beneficiario) AS Beneficiario,
          T.Description,T.Menu, T.Creation_Date, T.Encargado, T.IsArchived, T.Id_Seccional,
        IF (T.SubMenu3 IN (SELECT SubMenu3 From db_liwa.Tickets_New WHERE SubMenu3 = 'Agendamiento visita'),'Visita', 'No visita'),
        IF (T.Id_Tickets IN(SELECT Id_Ticket FROM db_liwa.Maintenance_new),'Agendado', 'Sin Agendar')
        FROM db_liwa.Tickets_New T WHERE
        (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and id_tipoProyecto = T.Id_Proyecto) AND T.IsDelete=0 AND T.IsActive=1 AND T.Id_Usuario ='${this.global.Id_Usuario}';`
        })
        // : (pdata9 = { option: "insertar2", texto: `
        // SELECT T.Id_Tickets,(db_liwa.getdays(Creation_Date, NOW())) AS Semaforo,
        // (SELECT S.Name_Status FROM db_liwa.Ticket_Statuses S WHERE S.Id_Statuses=T.Id_Statuses)AS Estado,
        // (SELECT P.Nombre_Completo FROM db_liwa.AOM P WHERE P.Id=T.Id_Beneficiario  AND P.IsDelete=0) AS Beneficiario,
        // T.Description,T.LastInsert AS Creacion_ticket, T.Menu,T.SubMenu1,T.SubMenu2,T.SubMenu3, T.Creation_Date, T.Encargado, T.IsArchived, T.Id_Seccional,T.Id_Beneficiario,
        // (SELECT H.Visit_Hour_Init  FROM db_liwa.Maintenance_new H WHERE H.Id_Ticket=T.Id_Tickets AND H.IsDelete=0) AS Hora_Inicial,
        // (SELECT H.Visit_Hour_finish FROM db_liwa.Maintenance_new H WHERE H.Id_Ticket=T.Id_Tickets AND H.IsDelete=0) AS Hora_Final,
        // (SELECT H.Visit_day FROM db_liwa.Maintenance_new H WHERE H.Id_Ticket=T.Id_Tickets AND H.IsDelete=0) AS Dia_Agendado,
        //     (SELECT H.Id_Maintenance FROM db_liwa.Maintenance_new H WHERE H.Id_Ticket=T.Id_Tickets AND H.IsDelete=0) AS Id_Maintenance,
        // IF (T.SubMenu3 IN (SELECT SubMenu3 From db_liwa.Tickets_New WHERE IsDelete=0 AND SubMenu3 = 'Agendamiento visita'),'Visita', 'No visita'),
        // IF (T.Id_Tickets IN(SELECT Id_Ticket FROM db_liwa.Maintenance_new M WHERE M.IsDelete=0 ),'Agendado', 'Sin Agendar')
        // FROM db_liwa.Tickets_New T Where (SELECT C.Id_Client FROM db_liwa.tipoProyecto C Where C.Id_Client = ${this.global.Id_Client} and id_tipoProyecto = T.Id_Proyecto) AND
        //  T.IsDelete=0 AND T.IsActive=1 ORDER BY Semaforo Desc;
        //  ` });
        : (pdata9 = {
          option: "insertar2", texto: `
          SELECT T.Id_Tickets, (db_liwa.getdays(Creation_Date, NOW())) AS Semaforo, S.Name_Status AS Estado, P.Nombre_Completo AS Beneficiario,T.Description,T.LastInsert AS Creacion_ticket, T.Menu,T.SubMenu1,T.SubMenu2,T.SubMenu3, T.Creation_Date, T.Encargado, T.IsArchived, T.Id_Seccional,T.Id_Beneficiario,
          H.Visit_Hour_Init AS Hora_Inicial, H.Visit_Hour_finish AS Hora_Final, H.Visit_day AS Dia_Agendado, H.Id_Maintenance AS Id_Maintenance,
                  IF (T.SubMenu3 IN (SELECT SubMenu3 From db_liwa.Tickets_New WHERE IsDelete=0 AND SubMenu3 = 'Agendamiento visita'),'Visita', 'No visita'),
                  IF (T.Id_Tickets IN(SELECT Id_Ticket FROM db_liwa.Maintenance_new M WHERE M.IsDelete=0 ),'Agendado', 'Sin Agendar') FROM db_liwa.Tickets_New T
          INNER JOIN db_liwa.Ticket_Statuses S ON S.Id_Statuses=T.Id_Statuses
          INNER JOIN db_liwa.AOM P ON P.IsDelete=0  AND P.Id=T.Id_Beneficiario
          INNER JOIN db_liwa.tipoProyecto C ON C.id_tipoProyecto = T.Id_Proyecto AND C.Id_Client = ${this.global.Id_Client}
          LEFT JOIN db_liwa.Maintenance_new H ON T.Id_Tickets=H.Id_Ticket AND H.IsDelete=0
          WHERE  T.IsDelete=0 AND T.IsActive=1 GROUP BY T.Id_Tickets ORDER BY Semaforo Desc;
         ` });
    this.global.consultar(pdataO, async (err9, response9) => {
      const respuesta = await response9
      let crtico = [],
        moderado = [],
        leve = [],
        cerrado = [];
      for (let i = 0; i < respuesta.length; i++) {
        if (
          respuesta[i][2] != "Cerrado por Visita técnica" &&
          respuesta[i][2] != "Cerrado por Call Center" &&
          respuesta[i][2] != "Cerrado por el usuario" &&
          respuesta[i][2] != "Cerrado por el Operador"
        ) {
          if (respuesta[i][1] >= 1 && respuesta[i][1] <= 6 && respuesta[i][2]) {
            leve.push(respuesta[i]);
          } else if (respuesta[i][1] >= 7 && respuesta[i][1] <= 13) {
            moderado.push(respuesta[i]);
          } else if (respuesta[i][1] >= 14) {
            crtico.push(respuesta[i]);
          }
        } else {
          cerrado.push(respuesta[i]);
        }
        // console.log(response9[i])
      }
      const pb = crtico.concat(moderado.concat(leve.concat(cerrado)));
      console.log("tetetetetetetete", pb);
      // this.tickets = crtico.concat(moderado.concat(leve.concat(cerrado)))
      this.tickets = pb;
      console.log(this.tickets, "estan llegando ??");
      setTimeout(() => {
        $(function () {
          this.table = $("#userTicket").DataTable({
            // order: [[3, "desc"]],
            // orderBy: 'numbers',
            order: true,
            // orderCellsTop: ,
            // orderClasses: [[3, "desc"]],
            responsive: false,
            destroy: true,
            retrieve: true,
            paging: true,
            info: false,
            pagingType: "numbers",
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
        })
        // : console.log();
      }, 800)
      this.spinner.hide();
    });
  }
  createTicket() {
    this.ruta.navigateByUrl("/crear-ticket");
  }
  ver(data) {
    this.global.Id_Ticket = data[0];
    const pdataTicket = {
      option: 'insertar2',
      texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, A.Id_Proyecto FROM db_liwa.EncuestasAOM A INNER JOIN db_liwa.Tickets_New T ON T.Id_Beneficiario = A.Id_Encuesta WHERE  A.NUI LIKE '%${data[0]}%' OR T.Id_Tickets LIKE '%${data[0]}%';`
    }
    this.global.consultar(pdataTicket, (err, response) => {
      if (response.length === 1) {
        console.log('Pdata Ticket', response);
        let benefits = response;
        this.global.informacion = benefits;
        window.localStorage.setItem(
          "informacion",
          JSON.stringify(this.global.informacion)
        );
        this.global.informacion = JSON.parse(
          window.localStorage.getItem("informacion")
        );
        this.ruta.navigateByUrl("/ver-ticket");
      }else{
        this.alerta.descErr("No se encontro relacionado el beneficiario. Esto puede ser porque fue eliminado.");
      }
    });
  }
  modificarTiket(data) {
    this.global.banderaModificaionTicket = true;
    this.global.informacionBeneficiario = data;
    this.global.Id_Ticket = data[0];
    this.ruta.navigateByUrl("/historico-usuario");
  }
  //            SEMAFORO
  stateBadge(data: any, estado: any, posicion: any) {
    if (
      estado != "Cerrado por Visita técnica" &&
      estado != "Cerrado por Call Center" &&
      estado != "Cerrado por el usuario" &&
      estado != "Cerrado por el Operador"
    ) {
      if (data >= 1 && data <= 6) {
        this.tickets[posicion][8] = "Leve";
        return "badge badge-leve"; // am
      } else if (data >= 7 && data <= 13) {
        this.tickets[posicion][8] = "Moderado";
        return "badge badge-warning"; //nj
      } else if (data >= 14) {
        this.tickets[posicion][8] = "Critico";
        return "badge badge-danger"; // rj
      }
    } else {
      this.tickets[posicion][8] = "Cerrado";
      return "badge badge-success";
    }
  }
  ordenamientoPorImportancia(stateBadge) {
    switch (stateBadge) {
      case "Critico":
        return "0";
      case "Moderado":
        return "2";
      case "Leve":
        return "3";
      default:
        return "4";
    }
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      const query =
        "UPDATE db_liwa.Tickets_New SET IsDelete = '1' WHERE Id_Tickets ='" +
        data +
        "';";
      console.log("Seleccionado", query);
      const pdata1 = { option: "insertar", texto: query };
      $.ajax({
        data: pdata1,
        cache: false,
        dataType: "json",
        type: "post",
        url: "https://www.php.engenius.com.co/DatabaseLIWA.php",
        success(data, textStatus, jQxhr) {
          console.warn("bien", data, textStatus);
        },
        error(jqXhr, textStatus, errorThrown) {
          console.warn("mal");
        },
      })
        .then((response) => {
          this.alerta.descValid("El ticket se ha cerrado con éxito");
          this.enviarcorreo(data);
          this.spinner.hide();
          this.ngOnInit();
        })
        .catch((err) => {});
    }, 500);
  }
  importancia(data) {
    if (data == "AAA") {
      return "badge badge-danger";
    }
    if (data == "AA") {
      return "badge badge-warning";
    }
    if (data == "A") {
      return "badge badge-success";
    }
  }
  importanciaTitle(data) {
    if (data == "AAA") {
      return "URGENTE";
    }
    if (data == "AA") {
      return "MEDIO";
    }
    if (data == "A") {
      return "BAJO";
    }
  }
  //////////// DELETE VALIDATION ///////////////////////////

  deleteVal(data: any) {
    Swal.fire({
      title: "<strong>¿Está seguro que desea eliminar el ticket?</strong>",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: "#093579",
      cancelButtonText: "Cancelar",
      cancelButtonAriaLabel: "Thumbs up, great!",
      confirmButtonText: "Si, estoy seguro",
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(data);
      } else {
        this.ngOnInit();
      }
    });
  }
  //END DELETE VALIDATION //
  enviarcorreo(idTicket) {
    // setTimeout(() => {
      const pdata9 = {
        option: "mailC",
        ticket: idTicket.toString(),
        texto: "Terminado",
        user: this.global.USERDATALIWA[8],
        novedad: "La PQRS ha sido cerrado por el usuario.",
      };
      console.log(pdata9);
      this.global.consultar2(pdata9, async (err2, response2) => {
        if ( await response2) {
          console.log("Datos correo", await response2);
        } else {
          console.log("ERROR---------------------", err2);
        }
      });
    // }, 500);
  }
  modal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-vinculate" })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  referenciaArchivo(ev) {
    this.file = ev.target.files[0];
    this.subiendoandoExcel2();
  }
  referenciaArchivoFI(ev) {
    let FI = ev.target.value;
    this.fechaI = moment(FI).format("YYYY-MM-DD 00:00:00")
  }
  referenciaArchivoFF(ev) {
    let FF = ev.target.value;
    this.fechaF = moment(FF).format("YYYY-MM-DD 23:59:59")
  }

  subiendoandoExcel2() {
    this.spinner.show();
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      if (MEDIDORESEXCEL[0].hasOwnProperty('Codigo')) {
        console.log('si tiene codigo')
        if (MEDIDORESEXCEL[0].hasOwnProperty('NUI')) {
          console.log('si tiene nui')
          if (MEDIDORESEXCEL[0].hasOwnProperty('Cedula')) {
            console.log('si tiene cedula')
            if (MEDIDORESEXCEL[0].hasOwnProperty('Tipo_PQR')) {
              console.log('si tiene tipo pqrs')
              if (MEDIDORESEXCEL[0].hasOwnProperty('Submenu1')) {
                console.log('si tiene submenu1')
                if (MEDIDORESEXCEL[0].hasOwnProperty('Submenu2')) {
                  console.log('si tiene submenu2')
                  if (MEDIDORESEXCEL[0].hasOwnProperty('Submenu3')) {
                    console.log('si tiene submenu3')
                    if (MEDIDORESEXCEL[0].hasOwnProperty('Descripcion')) {
                      console.log('si tiene descripcion')
                      if (MEDIDORESEXCEL[0].hasOwnProperty('Dia')) {
                        console.log('si tiene dia')
                        if (MEDIDORESEXCEL[0].hasOwnProperty('Mes')) {
                          console.log('si tiene mes')
                          if (MEDIDORESEXCEL[0].hasOwnProperty('Ano')) {
                            console.log('si tiene ano')
                            this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                            const fecha = `${this.MEDIDORESEXCEL[0].Dia}-${this.MEDIDORESEXCEL[0].Mes}-${this.MEDIDORESEXCEL[0].Ano}`;
                            console.log(fecha, 'fecha')
                            console.log();
                            this.Registrar = true;
                            this.spinner.hide();
                          } else {
                            this.alerta.excelErr('Ano');
                            this.spinner.hide();
                          }
                        } else {
                          this.alerta.excelErr('Mes');
                          this.spinner.hide();
                        }
                      } else {
                        this.alerta.excelErr('Dia');
                        this.spinner.hide();
                      }
                    } else {
                      this.alerta.excelErr('Descripcion');
                      this.spinner.hide();
                    }
                  } else {
                    this.alerta.excelErr('Submenu3');
                    this.spinner.hide();
                  }
                } else {
                  this.alerta.excelErr('Submenu2');
                  this.spinner.hide();
                }
              } else {
                this.alerta.excelErr('Submenu1');
                this.spinner.hide();
              }
            } else {
              this.alerta.excelErr('Tipo_PQR');
              this.spinner.hide();
            }
          } else {
            this.alerta.excelErr('Cedula');
            this.spinner.hide();
          }
        } else {
          this.alerta.excelErr('NUI');
          this.spinner.hide();
        }
      } else {
        this.alerta.excelErr('Codigo');
        this.spinner.hide();
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }
  RegistrarExcel() {
    this.spinner.show();
    let masivo = new Array();
    if (this.Registrar == true) {
      for (let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
        const fecha = `${this.MEDIDORESEXCEL[i].Dia}-${this.MEDIDORESEXCEL[i].Mes}-${this.MEDIDORESEXCEL[i].Ano}`;
        masivo.push({
          idUser: this.global.Id_Usuario,
          codigo: this.MEDIDORESEXCEL[i].Codigo,
          nui: this.MEDIDORESEXCEL[i].NUI,
          cedula: this.MEDIDORESEXCEL[i].Cedula,
          tipo_pqr: this.MEDIDORESEXCEL[i].Tipo_PQR,
          submenu1: this.MEDIDORESEXCEL[i].Submenu1,
          submenu2: this.MEDIDORESEXCEL[i].Submenu2,
          submenu3: this.MEDIDORESEXCEL[i].Submenu3,
          descripcion: this.MEDIDORESEXCEL[i].Descripcion,
          fechaCreacion: moment(fecha, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      setTimeout(() => {

         const pdata = {
          option: "excel1",
          datos: masivo,
        }
        this.global.consultarLocal(pdata, (err, response) => {
          console.log(response);

          if (response?.success === true || response !== undefined) {
            console.log(response.informe);
            this.spinner.hide();
            this.alerta.descValid(response.message);
            this.ngOnInit();
          } else {
            this.spinner.hide();
            this.alerta.descErr('Error: ' + response.message);
          }
        });
        console.log('MASIVO DEF', masivo);
      }, 500);
    }
  }


  /* PARAMS: codigo, NUI, CEDULA, TIPO_PQR, Submenu1, submenu2, submenu3, descripcion, fechaCreacion*/

  subiendoandoExcel23() {
    this.spinner.show();
    let fileReader = new FileReader();
    console.log(fileReader, "fileReader");
    fileReader.onload = (e) => {
      console.log(e, "Holaaa");
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
        var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        console.log(MEDIDORESEXCEL[0], "MEDIDORESEXCEL <--")
        if (MEDIDORESEXCEL[0].hasOwnProperty("Codigo")) {
          console.log("tiene codigo");
          for (let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
            let codigo = this.MEDIDORESEXCEL[i].Codigo;
            console.log(codigo, "codigo");
          }
          if (MEDIDORESEXCEL[0].hasOwnProperty("NUI")) {
            console.log("tiene NUI");
            if (MEDIDORESEXCEL[0].hasOwnProperty("Cedula")) {
              console.log("tiene cedula");
              if (MEDIDORESEXCEL[0].hasOwnProperty("Tipo_PQR")) {
                console.log("si tiene tipo pqr");
                if (MEDIDORESEXCEL[0].hasOwnProperty("Submenu1")) {
                  console.log("tiene submenu1");
                  if (MEDIDORESEXCEL[0].hasOwnProperty("Submenu2")) {
                    console.log("tiene submenu2");
                    if (MEDIDORESEXCEL[0].hasOwnProperty("Submenu3")) {
                      console.log("tiene submenu3");
                      if (MEDIDORESEXCEL[0].hasOwnProperty("Descripcion")) {
                        console.log("tiene descripcion");
                        if (MEDIDORESEXCEL[0].hasOwnProperty("Fecha creacion")) {
                          console.log("tiene fechaCreacion");
                          this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                          this.Registrar = true;
                          this.spinner.show();
                          let masivo = new Array();

                          if (this.Registrar == true) {
                            for (let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
                              masivo.push({
                                idUser: this.global.Id_Usuario,
                                codigo: this.MEDIDORESEXCEL[i].Codigo,
                                nui: this.MEDIDORESEXCEL[i].NUI,
                                cedula: this.MEDIDORESEXCEL[i].Cedula,
                                tipo_pqr: this.MEDIDORESEXCEL[i].Tipo_PQR,
                                submenu1: this.MEDIDORESEXCEL[i].Submenu1,
                                submenu2: this.MEDIDORESEXCEL[i].Submenu2,
                                submenu3: this.MEDIDORESEXCEL[i].Submenu3,
                                descripcion: this.MEDIDORESEXCEL[i].descripcion,
                                fechaCreacion: moment(this.MEDIDORESEXCEL[i].fechaCreacion).format("YYYY-MM-DD HH:mm:ss"),
                              });

                              console.log(masivo, "masivo");
                            }
                            setTimeout(() => {
                              console.log('MASIVO DEF', masivo);

                              const pdata = {
                                option: "excel1",
                                datos: masivo,
                              }

                              this.global.consultarLocal(pdata, (err, response) => {
                                if (response) {
                                  console.log(response.informe);
                                  this.spinner.hide();
                                  this.alerta.descValid('Proceso iniciado, esto se puede  llegar a demorar!');
                                  this.ngOnInit();
                                } else if (response == null || response == undefined || err == true) {
                                  this.spinner.hide();
                                  this.alerta.descErr('Error, por favor intente de nuevo!');
                                }
                              });
                            }, 500);
                          }
                        } else {
                          console.log("no tiene fechaCreacion");
                          this.alerta.excelErr('identification');
                        }
                      } else {
                        console.log("no tiene descripcion");
                      }
                    } else {
                      console.log("no tiene submenu3");
                    }
                  } else {
                    console.log("no tiene submenu2");
                  }
                } else {
                  console.log("no tiene submenu1");
                }
              } else {
                console.log("no tiene tipo pqr");
              }
            } else {
              console.log("no tiene cedula");
            }
          } else {
            console.log("no tiene NUI");
          }
        } else {
          console.log("no tiene codigo");
        }
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }
  verArchivo() {
    window.open("https://php.engenius.com.co/Formatos/Excel/Ejemplo_Carga_Masiva_PQRS.xlsx", "_blank");
  }
  Agendar(idTicket) {
    this.global.Id_Ticket = idTicket;
    this.global.withoutTicket = false;
    this.global.isAgended = true;
    this.ruta.navigateByUrl("/crear-visita");
  }
  async generarExcel() {
    this.spinner.show();
      const pdata = {
        option: "GestionSAC",
        fechaI: this.fechaI,
        fechaF: this.fechaF,
        client: this.idCliente
      };
        console.log(this.fechaI,this.fechaF,'este es el problema')
        await this.global.consultarFormatoSui(pdata, async (err, response) => {
        console.log(response)
        const infoExcel = response;
        console.log(response,"FI",this.fechaI,"FF",this.fechaF,"Cliente",this.idCliente);
        this.Toexcel = [
          [ `Id_Tickets`,`Id_Gestion`,`Id_Usuario`,`Id_Proyecto`,`Id_Seccional`,`Semaforo`,`Id_Beneficiario`,`Menu`,`SubMenu1`,`SubMenu2`,`SubMenu3`,`Creation_Mantenimiento`,
          `Creation_Mensaje`,`Descripción`,`Id_Encuesta`,`Nombre_Completo`,`Cedula`,`Telefono`,`Tarifa`,`Vereda`,`Cod_Mun`,`Observaciones`,`NUI`,`NombreProyecto`,`NombreSeccionales`,
          `Descripcion Ticke`,`Creador_gestion`,`NombreTecnico`,`FechaVisita`,`DescrTecnico`,`Semaforo en Dias`,`IdMantenimeinto`,`idTecnico`,`tiposMantenimiento`,`estadoMantenimiento`,
          `ultimaGestionVisita`
          ],
        ];
        for (let valor of infoExcel) {
          let {
            Id_Tickets,
            Id_Gestion,
            Id_Usuario,
            Id_Proyecto,
            Id_Seccional,
            Id_Statuses,
            Id_Beneficiario,
            Menu,
            SubMenu1,
            SubMenu2,
            SubMenu3,
            Creation_Date,
            Creation_Date_Mn,
            Description,
            Id_Encuesta,
            Nombre_Completo,
            Cedula,
            Telefono,
            Tarifa,
            Vereda,
            Cod_Mun,
            Observaciones,
            NUI,
            NombreProyecto,
            NombreSeccionales,
            EstadoTicke,
            EstadoTickeNum,
            Creador_gestion,
            NombreTecnico,
            FechaVisita,
            DescrTecnico,
            Semaforo,
            IdMantenimeinto,
            idTecnico,
            tiposMantenimiento,
            estadoMantenimiento,
            ultimaGestionVisita
          } = valor;
          // console.log(Creation_Date, "este es el Creation_Data");
          this.Toexcel.push([
            Id_Tickets, // este es el id del SMS en realidad
            Id_Gestion,
            Id_Usuario,
            Id_Proyecto,
            Id_Seccional,
           Id_Statuses == 11 ||Id_Statuses == 10 ||Id_Statuses == 6 ||Id_Statuses == 7 // id statuses ticket
              ? "Cerrado"
              : Semaforo <= 6
              ? "Leve"
              : Semaforo >= 7 && Semaforo <= 14
              ? "Moderado"
              : Semaforo >= 15
              ? "Critico"
              : "-",
              Id_Beneficiario,
              Menu,
              SubMenu1,
              SubMenu2,
              SubMenu3,
              Creation_Date,
              Creation_Date_Mn,
              Description,
              Id_Encuesta,
              Nombre_Completo,
              Cedula,
              Telefono,
              Tarifa,
              Vereda,
              Cod_Mun,
              Observaciones,
              NUI,
              NombreProyecto,
              NombreSeccionales,
              EstadoTicke,
              Creador_gestion,
              NombreTecnico,
              FechaVisita,
              DescrTecnico,
              Semaforo,
              IdMantenimeinto,
              idTecnico,
              tiposMantenimiento,
              estadoMantenimiento,
              ultimaGestionVisita
          ]);
        } //      los que estan en 12 Son datos faltante

        this.excelService.exportAsExcelFile2(
          this.Toexcel,
          "Reporte asesores",
          "Reporte Asesores SAC" + Date.now().toString()
        );
        // console.log(this.Toexcel,'este es el toxcel')
        this.spinner.hide();

      });

  }
  openEditRate(content, Id_Ticket) {
    const pdata9 = {
      option: "insertar2",
      texto: `SELECT M.Visit_Hour_init,M.Visit_Hour_finish,M.Visit_day,(SELECT UserName FROM db_liwa.User WHERE Id_User = M.Id_Technical) AS Nombre_Tecnico fROM db_liwa.Maintenance_new M WHERE Id_Ticket = '${Id_Ticket}' AND IsDelete=0;`,
    };
    this.global.consultar(pdata9, (err9, response9) => {
      this.infoVisitaTecnica = response9;
      this.modalService
        .open(content, { ariaLabelledBy: "modal-editRate" })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    });
  }

  openDescripcion(content, itemValue) {
    this.descripcion = itemValue;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-descripcion" });
  }

  formatt(item) {
    return moment(item).format('YYYY/MM/DD HH:mm')

  }
}
