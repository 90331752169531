import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';

@Component({
  selector: 'app-informes-de-recargas',
  templateUrl: './informes-de-recargas.component.html'
})
export class InformesDeRecargasComponent implements OnInit {
  clientClass;
  Graficas = false;
  DIA = new Array();
  color;
  colorg;
  DataCue;
  Tipo1 = new Array();
  Tipo2 = new Array();
  Datos = new Array();
  arrDispac: any[] = [];
  setDiagram: boolean = false;

  constructor(public global: GlobalService,
    private spinner: NgxSpinnerService,
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    const pdata2 = { option: 'ULT_Dispac' };
    this.global.consultar(pdata2, (err2, response2) => {
      this.arrDispac = response2;
      console.log(this.arrDispac);
      this.setDiagram = true;

    });
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.isInforme();
  }

  ColorLabels() {
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
  }

  ProcesarData3() {
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.DIA.length; index++) {
      series.push(this.DIA[index][0]);
      labels.push('Día ' + this.DIA[index][2]);
      background.push('rgba(75, 192, 192, 0.2)');
      color.push('rgba(75, 192, 192, 1)');
    }
    this.DataCue = {
      'labels': labels,
      'series': series
    };
    const canvas = document.getElementById('myChart3') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas',
    //       data: series,
    //       backgroundColor: background,
    //       borderColor: color,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     maintainAspectRatio: false,
    //     responsive: true,
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //         },
    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Día del Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         gridLines: {
    //           color: this.colorg,
    //           display: false
    //         },
    //         ticks: {
    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Número de Recargas'
    //         },
    //       }]
    //     }
    //   }
    // });
  }


  ProcesarData2() {
    this.ColorLabels();
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.Tipo1.length; index++) {
      series.push(this.Tipo1[index][0]);
      labels.push(this.Tipo1[index][2] + '-' + this.Tipo1[index][3]);
      background.push('rgba(255, 99, 132, 0.2)');
      color.push('rgba(255, 99, 132, 1)');
    }
    const series2 = new Array();
    const labels2 = new Array();
    const color2 = new Array();
    const background2 = new Array();
    for (let index = 0; index < this.Tipo2.length; index++) {
      series2.push(this.Tipo2[index][0]);
      labels2.push(this.Tipo2[index][2] + '-' + this.Tipo2[index][3]);
      background2.push('rgba(54, 162, 235, 0.2)');
      color2.push('rgba(54, 162, 235, 1)');
    }
    const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas $20.000',
    //       data: series,
    //       backgroundColor:
    //         background
    //       ,
    //       borderColor:
    //         color
    //       ,
    //       borderWidth: 1
    //     },
    //     {
    //       label: 'Recargas $30.000',
    //       data: series2,
    //       backgroundColor:
    //         background2
    //       ,
    //       borderColor:
    //         color2
    //       ,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //         },
    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Número de Recargas'
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //           display: false,
    //         },
    //       }]
    //     }
    //   }
    // });
    // myChart.update();
  }


  ProcesarData() {
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.Datos.length; index++) {
      series.push(this.Datos[index][0]);
      labels.push(this.Datos[index][2] + '-' + this.Datos[index][3]);
      background.push('rgba(153, 102, 255, 0.2)');
      color.push('rgba(153, 102, 255, 1');
    }
    this.DataCue = {
      'labels': labels,
      'series': series
    };
    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas',
    //       data: series,
    //       backgroundColor: background,
    //       borderColor: color,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     scales: {
    //       xAxes: [{
    //         gridLines: {
    //           color: this.colorg, // Eje x color verde
    //         },
    //         ticks: {
    //           fontColor: this.color,
    //         },

    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {

    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: 'Número de Recargas',
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //           display: false
    //         }
    //       }]
    //     }
    //   }
    // });
  }

  isInforme() {
      this.Graficas = true;
      const pdata4 = { option: 'enelardia' };
      this.global.consultarvv(pdata4, (err3, response3) => {
        if (err3 == null) {
          console.log('DATA', response3);
          this.DIA = response3;
          this.ProcesarData3();
          const pdata2 = { option: 'enelartipo1' };
          this.global.consultarvv(pdata2, (err1, response1) => {
            if (err1 == null) {
              console.log('DATA', response1);
              this.Tipo1 = response1;
              const pdata3 = { option: 'enelartipo2' };
              this.global.consultarvv(pdata3, (err2, response2) => {
                if (err2 == null) {
                  console.log('DATA', response2);
                  this.Tipo2 = response2;
                  this.ProcesarData2();
                  const pdata1 = { option: 'enelarrecargas' };
                  this.global.consultarvv(pdata1, (err, response) => {
                    if (err == null) {
                      console.log('DATA', response);
                      this.Datos = response;
                      this.ProcesarData();
                    }
                  });
                  this.ColorLabels();
                }
              });
            }
          });
        }
      });
  }

}
