import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-replanteo-usuarios',
  templateUrl: './replanteo-usuarios.component.html'
})
export class ReplanteoUsuariosComponent implements OnInit {
  proyectos: any[] = [];
  seccionales: any[] = [];
  idSeccional ="Seleccione una seccional...";
  idProject = "Seleccione un proyecto...";
  clientClass;
  buttonSeccional:boolean = false;
  seccionalOn: boolean = false;
  text = "Seleccione un proyecto...";
  textS = "Seleccione una seccional...";
  constructor(protected global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    this.spinner.show();

      const datos = { option: 'tipoProyecto', client: this.global.Id_Client };
      this.global.consultar(datos, async (err, response) => {
        this.proyectos = await response;
        this.spinner.hide();
      });

  }

  changeSelect(valueId) {
    this.spinner.show();
    this.idSeccional = "Seleccione una seccional...";
    this.buttonSeccional = false;
      const pdata4 = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '${valueId}' AND IsDelete=0 AND IsActive=1;` };
      this.global.consultar(pdata4, async (err4, response4) => {
        let res:any [] = await  response4;
        if (res.length == undefined || res.length == 0) {
          this.seccionales = [];
          this.seccionalOn = true;
          this.buttonSeccional = false;
          this.spinner.hide();
        } else {
          this.seccionales = response4;
          this.seccionalOn = true;
          this.spinner.hide();
        }
      });

  }

  selectSeccional() {
    this.global.idSeccional = this.idSeccional;
    sessionStorage.setItem('replanteoSeccional', this.idSeccional);
    this.route.navigateByUrl('/replanteo');
  }



}
