import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  clientClass;
  proyectos =[];
  constructor(public global: GlobalService,
              public ruta: Router,
              public alerta: GlobalAlertService, 
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA;

  }

}
