import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  stateClass: boolean;
  stateTitle: boolean;
}
export const INTERNAL: RouteInfo[] = [
  { path: '/gestion-llamadas',  title: 'Modulo de Carga de gestion Telefonica', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true  },
  { path: '/descargables',  title: 'Modulo de Gestion de Informes', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true  },
  { path: '/informacion-usuario',  title: 'Informacion de Usuario', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true  },
  { path: '/mantenimiento-tecnico', title: 'Mantenimientos del Tecnico', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true },
  { path: '/formato-sui', title: 'Formato SUI', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true },
  { path: '/formato', title: 'Seccional', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true },
  { path: '/user-profile', title: 'Mi perfil', icon: 'ni-single-02', class: '', stateClass: true, stateTitle: true },
  { path: '/ver-ticket', title: 'Detalles del ticket', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/ver-visita', title: 'Detalles de la visita', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/visitas', title: 'Visitas de mantenimiento', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/administracion-tecnicos', title: 'Aministracion de Tecnicos', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-preliminar', title: 'Listado preliminar', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-definitivo', title: 'Listado definitivo', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/formulario-preliminar', title: 'Formulario', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/seccionales', title: 'Seccionales', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/beneficiarios', title: 'Beneficiarios', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/recepcion', title: 'Recepción e ingreso', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/alistamiento', title: 'Alistamiento', icon: 'ni-single-copy-04 text-success', class: '', stateClass: true, stateTitle: true },
  { path: '/demo-dispac', title: 'Demo dispac', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/p-instalacion', title: 'Instalación de la persona', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/visor-web-mapas', title: 'Visor web de mapas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/biblioteca-mapas', title: 'Biblioteca de mapas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/demo-dup', title: 'Instalación Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-preliminar-nazareth', title: 'Listado preliminar de usuarios Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-preliminar-redes-nazareth', title: 'Listado preliminar de redes Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-preliminar-disp-nazareth', title: 'Listado preliminar de equipos Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-definitivo-nazareth', title: 'Listado definitivo de usuarios Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-definitivo-redes-nazareth', title: 'Listado definitivo de redes Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/listado-definitivo-disp-nazareth', title: 'Listado definitivo de equipos Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/formulario-preliminar-nazareth', title: 'Encuesta preliminar de usuarios Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/formulario-preliminar-redes-nazareth', title: 'Encuesta preliminar de redes Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/formulario-preliminar-disp-nazareth', title: 'Encuesta preliminar de equipos Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/informes-de-recargas', title: 'Informes de recargas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/historial-de-recargas', title: 'Historial de recargas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/historicos-e-informacion', title: 'Historicos e informacion', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/fases-geb', title: 'Fase Aulas GEB', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/geb-instalacion', title: 'Instalación - Aulas GEB', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/replanteo', title: 'Replanteo de usuarios', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/replanteo-form', title: 'Formulario de replanteo', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/fomato', title: 'formatos', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false }
];

export const TITLES: RouteInfo[] = [
  { path: '/dashboard', title: 'Principal', icon: 'ni-tv-2 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/project', title: 'Listado de Proyectos', icon: 'ni-bullet-list-67 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/etapas', title: 'Etapas y Proyectos', icon: 'ni-calendar-grid-58 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/cartera', title: 'Cartera', icon: 'ni-money-coins color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/informes', title: 'Informes', icon: 'ni-paper-diploma color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/usuarios', title: 'Administración de Usuarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/editarp', title: 'Información del Proyecto', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/crear-usuario', title: 'Crear usuario', icon: 'ni-single-01 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Mapa-proyecto', title: 'Mapa del proyecto', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-usuarios', title: 'Listados de usuarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-redes', title: 'Listado de redes', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-dispositivos', title: 'Listado de dispositivos', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
];

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Principal', icon: 'ni-tv-2 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/project', title: 'Listado de Proyectos', icon: 'ni-bullet-list-67 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/etapas', title: 'Etapas y Proyectos', icon: 'ni-calendar-grid-58 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/cartera', title: 'Cartera', icon: 'ni-money-coins color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/informes', title: 'Informes', icon: 'ni-paper-diploma color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES2: RouteInfo[] = [
  { path: '/usuarios', title: 'Administración de usuarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/adm-ticket', title: 'Administración de tickets PQRS', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/adm-pqmn', title: 'Administración de preguntas y manuales', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },/*
  { path: '/adm-mantenimiento', title: 'Administración de mantenimiento',  icon: 'ni-settings color_icons', class: '' }, */
];
//Estas son las paginas del modulo que muestra si el rol no es de asesor
export const ROUTES3: RouteInfo[] = [
  { path: '/user-ticket', title: 'SAC', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/user-faq', title: 'Preguntas frecuentes', icon: 'ni-book-bookmark color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/user-manual', title: 'Manuales y documentación', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/contact', title: 'Contacto', icon: 'ni-mobile-button color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/gestion-llamadas', title: 'Gestion Llamadas', icon: 'ni-building color_icons', class: '', stateClass: true, stateTitle: true }
];
//Estas son las paginas del modulo que muestra si el rol es de asesor
export const ROUTES3_1: RouteInfo[] = [
  { path: '/user-ticket', title: 'SAC', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/user-faq', title: 'Preguntas frecuentes', icon: 'ni-book-bookmark color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/user-manual', title: 'Manuales y documentación', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/actualizacion-datos', title: 'Actualizar Datos Usuarios', icon: 'ni-building color_icons', class: '', stateClass: true, stateTitle: true }
];
export const ROUTES4: RouteInfo[] = [
  { path: '/pagina-principal', title: 'Pagina principal', icon: 'ni-app color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/descargables', title: 'Descargables', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/descargables', title: 'Instalaciones fotovoltaicas',  icon: 'ni-tv-2 color_icons', class: '' }
 /* { path: '/sedes', title: 'Sedes Sena',  icon: 'ni-compass-04 color_icons', class: '' } */
];
export const ROUTES5: RouteInfo[] = [
  //{ path: '/sede-providencia', title: 'IPSE-Providencia', icon: 'ni-compass-04 color_icons', class: '' , stateClass: false, stateTitle:false},
  { path: '/enelar', title: 'Enelar', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false },
  { path: '/revision-fotos', title: 'Revision fotos enelar', icon: 'ni-mobile-button color_icons', class: '', stateClass: false, stateTitle: false },
  { path: '/fenoge', title: 'SSFV Escuelas Fenoge', icon: 'ni-tv-2 color_icons', class: '', stateClass: false, stateTitle: false },
  { path: '/luminarias', title: 'Luminarias VUP', icon: 'ni-building color_icons', class: '', stateClass: true, stateTitle: true },
  //{ path: '/jd', title: 'CAT C18 Nazareth', icon: 'ni-spaceship color_icons', class: '' },
  { path: '/c-instalacion', title: 'Claro', icon: 'fas fa-phone color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/yutaho', title: 'Yutaho 69 Guajira', icon: 'ni-spaceship color_icons', class: '', stateClass: true, stateTitle: true },

  //{ path: '/aulas-geb', title: 'Aulas GEB', icon: 'ni-building color_icons', class: '', stateClass: false, stateTitle: false },
  { path: '/gensa-puerto-carreno', title: 'Gensa Puerto Carreño', icon: 'ni-paper-diploma color_icons', class: '', stateClass: false, stateTitle: false },
];
export const ROUTES6: RouteInfo[] = [
  { path: '/proyecto', title: 'Proyectos AOM', icon: 'fas fa-project-diagram color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/adq-beneficiarios', title: 'Adquisición de beneficiarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },  ESPERA NUEVA ORDEN
  { path: '/replanteo-usuarios', title: 'Replanteo de usuarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/pipeline-mayo', title: 'Puerto Carreño', icon: 'ni-compass-04 color_icons', class: '', stateClass: true, stateTitle: true },
  // { path: '/comunicaciones', title: 'Comunicaciones', icon: 'fas fa-phone color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/tenderos', title: 'Tenderos', icon: 'ni-shop color_icons', class: '', stateClass: true, stateTitle: true },

];
export const ROUTES7: RouteInfo[] = [
  { path: '/registro-fabricacion', title: 'Registro de fabricación', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/proceso-pruebas', title: 'Proceso y pruebas', icon: 'ni-settings color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/inventario', title: 'Herramientas y materias primas', icon: 'fas fa-toolbox color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/inventario-proyecto', title: 'Inventario', icon: 'fas fa-clipboard color_icons', class: '', stateClass: true, stateTitle: true },
  //{ path: '/registro-tarjeta', title: 'Registro de tarjetas', icon: 'fas fa-clipboard color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES8: RouteInfo[] = [
  { path: '/demo-dispac', title: 'Demo oficina', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/demo-dup', title: 'Demo Nazareth', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES9: RouteInfo[] = [
  { path: '/visitas', title: 'Visitas', icon: 'ni-user-run color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/administracion-tecnicos', title: 'Administracion De Tecnicos', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES10: RouteInfo[] = [
  { path: '/visor-web-mapas', title: 'Visor web de mapas', icon: 'fas fa-globe-americas color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/biblioteca-mapas', title: 'Biblioteca de mapas', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES11: RouteInfo[] = [
  { path: '/Mapa-proyecto', title: 'Mapa del proyecto', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-usuarios', title: 'Listados de usuarios', icon: 'ni-single-02 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-redes', title: 'Listado de redes', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/Listado-dispositivos', title: 'Listado de dispositivos', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true },
];
export const ROUTES12: RouteInfo[] = [
  { path: '/informes-de-recargas', title: 'Informes de recargas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/historial-de-recargas', title: 'Historial de recargas', icon: 'ni-single-copy-04 color_icons', class: '', stateClass: true, stateTitle: true },
  { path: '/historicos-e-informacion', title: 'Biblioteca de datos', icon: 'ni-books color_icons', class: '', stateClass: true, stateTitle: true }
];
export const ROUTES13: RouteInfo[] = [
  { path: '/enelar', title: 'Enelar', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false },
];

export const ROUTES14: RouteInfo[] = [
  { path: '/facturacion', title: 'Facturación', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false },
  { path: '/cliente', title: 'Cliente', icon: 'ni-single-02 color_icons', class: '', stateClass: false, stateTitle: false },
  // { path: '/pagos', title: 'Pagos', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false },
  // { path: '/gestionCartera', title: 'Gestion de cartera', icon: 'ni-shop color_icons', class: '', stateClass: false, stateTitle: false },
]
/*
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
    { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login',  icon:'ni-key-25 color_icons', class: '' },
    { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];*/

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  usuarios = new Array();
  idRol;
  menuItems: any[];
  menuItems2: any[];
  menuItems3: any[];
  menuItems4: any[];
  menuItems5: any[];
  menuItems6: any[];
  menuItems7: any[];
  menuItems8: any[];
  menuItems9: any[];
  menuItems10: any[];
  menuItems11: any[];
  menuItems12: any[];
  menuItems13: any[];
  menuItems14: any[];
  isCollapsed: boolean = true;
  call: boolean = true;
  call2: boolean = true;
  call3: boolean = true;
  call4: boolean = false;
  call5: boolean = true;
  call6: boolean = true;
  call7: boolean = true;
  call8: boolean = true;
  call9: boolean = true;
  call10: boolean = true;
  call11: boolean = true;
  call12: boolean = true;
  call13: boolean = true;
  call14:boolean = true;
  inCharge: boolean = false;

  constructor(private router: Router,
    public global: GlobalService,
    public spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      // console.log('user', this.global.Id_Usuario);
      const pdata8 = { option: 'user', user: this.global.Id_Usuario };
      this.global.consultar(pdata8, (err8, response8) => {
        // console.log('Datos Usuario', response8);
        this.usuarios = response8[0];
        this.inCharge = true;
        this.spinner.hide();
      });
      this.idRol = this.global.USERDATALIWA[2];
      if (this.idRol == 11) {
        this.menuItems5 = ROUTES5.filter(menuItem => menuItem.title == 'Enelar');
      } else {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.menuItems2 = ROUTES2.filter(menuItem => menuItem);
        if(this.idRol == 15) this.menuItems3 = ROUTES3_1.filter(menuItem => menuItem);
        else  this.menuItems3 = ROUTES3.filter(menuItem => menuItem);
        this.menuItems4 = ROUTES4.filter(menuItem => menuItem);
        this.menuItems5 = ROUTES5.filter(menuItem => menuItem);
        this.menuItems6 = ROUTES6.filter(menuItem => menuItem);
        this.menuItems7 = ROUTES7.filter(menuItem => menuItem);
        this.menuItems8 = ROUTES8.filter(menuItem => menuItem);
        this.menuItems9 = ROUTES9.filter(menuItem => menuItem);
        this.menuItems10 = ROUTES10.filter(menuItem => menuItem);
        this.menuItems11 = ROUTES11.filter(menuItem => menuItem);
        this.menuItems12 = ROUTES12.filter(menuItem => menuItem);
        this.menuItems13 = ROUTES13.filter(menuItem => menuItem);
        this.menuItems14 = ROUTES14.filter(menuItem => menuItem);

      }
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
      });
    }, 60);
  }
  IsActiveView(data) {
    if (data == true) {
      return 'Online';
    }
    if (data == false) {
      return 'Offline';
    }
  }
  isActiveBadge(data) {
    if (data == true) {
      return 'badge badge-success';
    }
    if (data == false) {
      return 'badge badge-danger';
    }
  }
}
