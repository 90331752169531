import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../global.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { OnDestroy } from '@angular/core';
import * as moment from "moment";
import { GlobalAlertService } from 'src/app/global-alert.service';
import { ColorEvent } from 'ngx-color';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editar-cliente',
  templateUrl: './editar-cliente.component.html',
  styleUrls: ['./editar-cliente.component.scss']
})
export class EditarClienteComponent implements OnInit {

  selectedFile;
  clientes = new Array();
  clientName;
  clientDocument;
  clientPhone;
  clientAddress;
  clientEmail;
  clientWeb;
  clientCountry;
  clientLogo;
  clientStyle;
  IdClient;
  Logo = '';
  clientClass;
  srcImg = '../../../assets/img/gallery/imgPlaceholder.png';
  ext;
  enterFlag: boolean = false;
  imgChanged:boolean = false;
  // ngx-color states
  title = 'app';
  primaryColor = '#fff';
  state = {
    h: 150,
    s: 0.50,
    l: 0.20,
    a: 1,
  };
  colors = [
    "#5e72e4",
    "#2dce89",
    "#11cdef",
    "#f5365c",
    "#212529",
    "#172b4d",
    "#093579",
    "#5603ad",
    "#8965e0",
    "#f3a4b5",
    "#fb6340",
    "#ffd600",
    "#2bffc6",
  ];
  constructor(public global: GlobalService,
    public routes: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService) {
    this.global.initData();
  }

  ngOnInit(): void {
    if (this.global.Clientdata == undefined) {
      this.routes.navigateByUrl('/pagina-principal');
    } else {
      this.spinner.show();
      setTimeout(() => {
        const pdata7 = { option: 'dataclient', client: this.global.Id_Client };
        this.global.consultar(pdata7, (err7, response7) => {
          console.log('Datos Cliente', response7);
          localStorage.setItem('CLIENTDATALIWA', response7[0]);
          this.clientes = response7[0];
          this.clientName = this.clientes[1];
          this.clientDocument = this.clientes[2];
          this.clientPhone = this.clientes[3];
          this.clientAddress = this.clientes[4];
          this.clientEmail = this.clientes[5];
          this.clientWeb = this.clientes[6];
          this.clientCountry = this.clientes[7];
          this.clientLogo = this.clientes[13];
          this.clientStyle = this.clientes[8];
          this.enterFlag = true;
          this.spinner.hide();
        });
      }, 800);
    }
  }
  exploreImg() {
    let elem: HTMLElement = document.querySelector('#exPhoto');
    elem.click();
  }
  onFileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {

        let imagen = new Image();
        imagen.src = event.target.result;
        imagen.onload = () => {
          this.clientLogo = event.target.result;
          this.srcImg = this.clientLogo;
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }

  }
  validExt(file: any) {
    let extension = file.split(';')[0];
    this.ext = '.' + extension;
  }
  Guardar() {
    this.spinner.show();
    try {
      setTimeout(() => {
        if (this.clientName == undefined || this.clientName == '' || this.clientDocument == undefined || this.clientAddress == '' || this.clientPhone == '' || this.clientEmail == undefined || this.clientEmail == '' || this.clientWeb == undefined || this.clientWeb == '' || this.clientCountry == '') {
          this.alerta.descErr('Error al crear nuevo usuario, verifique nuevamente que no hayan campos vacíos');
          this.spinner.hide();
        } else {
          const query = 'UPDATE db_liwa.Client SET ClientName = \'' + this.clientName + '\', ClientDocument = \'' + this.clientDocument + '\', ClientPhone = \'' + this.clientPhone + '\',ClientAddress = \'' + this.clientAddress + '\',ClientEmail = \'' + this.clientEmail +
            '\',ClientWebPage = \'' + this.clientWeb + '\',ClientCountry = \'' + this.clientCountry + '\',ClientLogo = \'' + this.clientStyle + '\',ClientImage = \'' + this.Logo +
            '\'' + ' WHERE Id_Client = \'' + this.clientes[0] + '\'';
          const pdata1 = { option: 'insertar3', texto: query };
          this.global.consultar(pdata1, (err, response) => {
            console.log(response, query);
            if (err == null && response == true) {
              this.alerta.descValid('El cliente se actualizó con éxito');
              this.ngOnInit();
              this.spinner.hide();
            } else {
              this.alerta.descErr('Error al editar cliente, verifique nuevamente que no hayan campos vacíos');
              this.spinner.hide();
            }
          });
        }
      }, 400);
    } catch (error) {
      console.log("error:", +error);
    }
  }
  loadLogo() {
    if(this.imgChanged == true){
      if (this.clientLogo == null || this.clientLogo == undefined || this.clientLogo == '') {
        this.alerta.descErr('Error al subir la foto, verifique nuevamente');
      } else {
        console.log('Foto', this.clientLogo);
        const FOTOO = this.clientLogo.slice(23, this.clientLogo.length);
        //nombre de la imagen en el servidor this.Photo
        const imageName = this.clientName + '_' + moment().unix() + this.ext;
        //const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        const imageFile = FOTOO;
        const data = new FormData();
        data.append('image', imageFile);
        data.append('nombre', imageName);
        data.append('ruta', this.clientName);
        $.ajax({
          url: 'https://fotos.engenius.com.co/subir_foto_Client.php',
          type: 'post',
          dataType: 'json',
          data,
          processData: false,
          contentType: false
        }).then((data1) => {
          console.log(data, data1);
          this.Logo = data1;
          if (this.Logo == 'BADEXT' || this.Logo == null || this.Logo == undefined || this.Logo == "" || this.Logo == 'NULL') {
            this.alerta.descErr('Error al subir la foto, verifique nuevamente');
          } else {
            this.Guardar();
          }
        });
      }
    } else {
      this.Logo = this.clientLogo;
      this.Guardar();
    }
  }
  goBack() {
    window.history.back();
  }
  changeComplete($event: ColorEvent): void {
    this.state = $event.color.hsl;
    this.primaryColor = $event.color.hex;
    console.log('changeComplete', $event);
    console.log(this.primaryColor);
    for (let i = 0; i < this.global.Clientcolors.length; i++) {
      if (this.global.Clientcolors[i].color == this.primaryColor) {
        this.clientStyle = this.global.Clientcolors[i].clase;
        document.querySelector('#gradient').setAttribute("class", this.clientStyle);
      }
    }

  }
}

