import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { ExcelService } from 'src/app/excel.service';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';
import { Select2OptionData } from 'ng-select2';
import { TimelineItem } from 'ngx-horizontal-timeline';
import Swal from 'sweetalert2';
import { GlobalAlertService } from 'src/app/global-alert.service';
@Component({
  selector: 'app-enelar',
  templateUrl: './enelar.component.html'
})
export class EnelarComponent implements OnInit {
  DataRecargas = new Array();
  Items = new Array();
  clientClass;
  mapType;
  Seleccion = '0';
  Style;
  Pines = new Array();
  isTable: boolean = false;
  listActive = true;
  list1Active: boolean = false;
  mapActive: boolean = false;
  adminActive: boolean = false;
  informesActive: boolean = false;
  diagramActive: boolean = true;
  DiagActive: boolean = false;
  graphicActive: boolean = false;
  historyActive: boolean = false;
  arrEnelar: any[] = [];
  setDiagram: boolean = false;
  sena = new Array();
  latitude = 6.5997156;
  longitude = -71.4886296;
  zoom = 9.5;
  PinesOri = new Array();
  Proyecto;
  Dispositivos;
  Instalacion;
  TipoReporte;
  GraficaEneGen;
  mov = 'MOVISTAR';
  Mifare;
  Mfallo;
  Battery;
  Puerta;
  Recarga
  items: TimelineItem[] = [];
  graphContent = [
    { titulo: 'PANEL SOLAR', imagen: '../../../assets/img/icons/common/svgEnergy/054-panel.svg' },
    { titulo: 'INVERSOR', imagen: '../../../assets/img/icons/common/svgEnergy/055-inversor.svg' },
    { titulo: 'SÁLIDA INSTALACIÓN', imagen: '../../../assets/img/icons/common/svgEnergy/056-salida.svg' },
    { titulo: 'CONTROLADOR', imagen: '../../../assets/img/icons/common/svgEnergy/042-gauge.svg' },
    { titulo: 'BATERÍA', imagen: '../../../assets/img/icons/common/svgEnergy/048-accumulator.svg' },
  ];
  SeleccionMes;
  FechaMes;
  Labels;
  Data;
  Data2;
  Graf = false;
  Graf2 = false;
  Tipo1 = new Array();
  Tipo2 = new Array();
  Datos = new Array();
  DIA = new Array();
  color;
  colorg;
  DataCue;
  Graficas = false;
  previous;
  chart;
  chart2;
  chart3;
  Pgenerada = new Array();
  Pconsumida = new Array();
  V = new Array();
  I = new Array();
  VP = new Array();
  IP = new Array();
  VACG = new Array();
  IACG = new Array();
  Horas;
  ValoresHoras;
  ContGen;
  ContCon;
  ValInstantaneo;
  TagData;
  ESP;
  InstalacionE = '';
  Instalacion2 = '';
  DatosSel;

  arrDispac: any[] = [];
  bandera = false;
  DataHistory;
  ALERTA = false;
  Bandera2 = false;
  VECTOR = new Array();
  Toexcel = [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']];
  Toexcel2 = [];
  itemsESP: Array<Select2OptionData>;
  SerialSTM;
  SerialRFID;
  Egen;
  Econ;
  FechaVen;
  Alarmas;
  UltimaEscritura;
  FechaVal;
  topUps: any[] = [];
  firstActive: boolean = true;
  scndActive: boolean = false;
  firstRate: boolean = false;
  scndRate: boolean = false;
  rate1 = null;
  rate2 = null;

  constructor(public global: GlobalService,
    public route: Router,
    public alert: GlobalAlertService,
    private spinner: NgxSpinnerService,
    public excelService: ExcelService,
    public render: Renderer2) {
    this.global.initData();
  }


  ngOnInit(): void {
    this.spinner.show();
    this.items.push({
      label: 'Test 1',
      icon: 'fa fa-address-book-o',
      active: true,
      title: 'Example 1',
      color: '16a085',
      command() {
        console.log('Action 1');
      }
    });

    this.items.push({
      label: 'Test 2',
      icon: 'fa fa-plus',
      title: 'Example 2',
      color: '2980b9',
      command() {
        console.log('Action 2');
      }
    });
    this.clientClass = this.global.CLIENTDATALIWA[8];
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.Style = this.global.mapStyle;
    const pdata2 = { option: 'ULT_Dispac' };
    console.log('ULT_Dispac', pdata2);
    this.global.consultar(pdata2, (err2, response2) => {
      this.arrDispac = response2;
      console.log(this.arrDispac);
      this.setDiagram = true;
    });
    const pdata3 = { option: 'pines' };
    this.global.consultarEnelar(pdata3, (err3, response3) => {
      console.log(response3);
      let datosnuevos = new Array();
      for (let i = 0; i < response3.length; i++) {
        console.log('Posicion', response3[i][10]);
        if (response3[i][11] == '1' && (response3[i][10] == 'Instalado' || response3[i][10] == 'En proceso')) {
          datosnuevos.push(response3[i]);
        }
      }
      this.spinner.show();
      setTimeout(() => {
        console.log('NUEVO ARRAY()', datosnuevos);
        window.scrollTo(0, 0);
        this.PinesOri = this.Pines = datosnuevos;
        this.isTable = true
        this.spinner.hide();
        this.rateManager();
        this.ngOnInit3();
      }, 300);
    })
  }
  rateManager() {
    try {
      const pdata = { option: 'enelar_topup' };
      this.global.consultar(pdata, (err, response) => {
        this.topUps = response;
      });
    } catch (error) {
      console.log(error);
    }
  }
  ngOnInit3() {
    this.ESP = new Array();
    const pdata2 = { option: 'ofline' };
    $.ajax({
      data: pdata2, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/Database.php',
      success(data, textStatus, jQxhr) {
        console.log('Datos Recibidos:', data);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.log(JSON.stringify(errorThrown));
      }
    }).then((response) => {
      console.log('Data', response);
      this.ESP = response;
      let obj: any[] = [];
      for (let i = 0; i < this.ESP.length; i++) {
        obj.push({ id: this.ESP[i][0], text: this.ESP[i][0] + ' - ' + this.ESP[i][2] });
      }
      this.itemsESP = obj;
      console.log(this.itemsESP);
    });
  }

  IRA(item) {
    console.log(item);
    this.global.ID_Enelar = item[5];
    this.route.navigateByUrl('/enelar2');
  }

  generarGrafico2() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 300);
    const canvas = document.getElementById('History2') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
// {    this.chart2 = new Chart(ctx, {
//       type: 'bar',
//       data: {
//         labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
//         datasets: [
//           {
//             label: 'Potencia Generada',
//             yAxisID: 'PTC',
//             backgroundColor: 'rgba(0, 102, 255, 0.1)',
//             borderColor: 'rgba(0, 102, 255)',
//             data: this.Pgenerada,
//             type: 'line'
//           },
//           {
//             label: 'Voltaje Panel',
//             yAxisID: 'VP',
//             backgroundColor: 'rgba(255, 206, 0)',
//             borderColor: 'rgba(255, 206, 0)',
//             data: this.VP
//           },
//           {
//             label: 'Corriente Panel',
//             yAxisID: 'IP',
//             backgroundColor: 'rgba(185, 39, 38)',
//             borderColor: 'rgba(185, 39, 38)',
//             data: this.IP
//           }]
//       },
//       options: {
//         scales: {
//           yAxes: [{
//             id: 'VP',
//             type: 'linear',
//             position: 'left',
//           }, {
//             id: 'IP',
//             type: 'linear',
//             position: 'right',
//           }, {
//             id: 'PTC',
//             type: 'linear',
//             position: 'left',
//           }]
//         }
//       }
//     });}
  }

  FechaNueva(fecha) {
    this.VECTOR[3] = fecha;
  }


  PDFfotosH() {
    try {
      setTimeout(() => {
        const imgData = new Image();
        imgData.crossOrigin = '';
        imgData.src = 'https://fotos.engenius.com.co' + this.global.CLIENTDATALIWA[13];
        const imgData2 = new Image();
        imgData2.crossOrigin = '';
        imgData2.src = '../../assets/img/Iconos2-19.png';
        const doc = new jsPDF
        const Title = ' Reporte de Datos Instalación Solar Fotovoltaica';
        const fechadia = moment(this.VECTOR[3]);
        fechadia.locale('es');
        console.log(fechadia);
        doc.setTextColor(3, 137, 246);
        doc.text(Title, 100, 45, 'center');
        const SubTitle = this.VECTOR[2];
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text('Usuario: ' + SubTitle, 100, 53, 'center');
        doc.text('Fecha de Lectura: ' + this.VECTOR[3], 100, 60, 'center');
        doc.addImage(imgData, 'PNG', 10, 5, 35, 35);
        doc.addImage(imgData2, 'PNG', 165, 2, 45, 45);
        let DATA = new Array();
        for (let index = 0; index < this.VP.length; index++) {
          DATA[index] = [index, this.VP[index] + ' V', this.IP[index] + ' A', this.V[index] + ' V', this.I[index] + ' A', this.VACG[index] + ' V', this.IACG[index] + ' A']
        }
        doc.autoTable({
          headStyles: { fillColor: [0, 132, 179], textColor: [255, 255, 255], fontSize: 11, lineColor: [255, 255, 255], lineWidth: 0.2 },
          head: [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']],
          body: DATA, styles: { fontSize: 10, lineColor: [0, 132, 179], lineWidth: 0.2, textColor: [0, 0, 0] }
          , startY: 70
          , theme: 'grid'
          , margin: { top: 40 }
          , showHead: 'firstPage'
        });
        const pageCount = doc.internal.getNumberOfPages();
        const arrr = new Array(pageCount);
        for (let j = 0; j < pageCount; j++) {
          arrr[j] = 'Hola';
        }
        setTimeout(() => {
          arrr.forEach((element, o) => {
            const dia = moment();
            dia.locale('es');
            doc.setTextColor(0, 0, 0);
            doc.setPage(o + 1);
            doc.setFontSize(8);
            doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
            doc.setFontSize(8);
            doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
          });
        }, 100);
        setTimeout(() => {
          doc.save('Reporte de Datos Instalación Solar Fotovoltaica' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
        }, 500);
      }, 500);
    } catch (err) {

    }
  }


  PDFfotosE() {
    for (let index = 0; index < this.VP.length; index++) {
      this.Toexcel.push([index.toString(), this.VP[index] + ' V', this.IP[index] + ' A', this.V[index] + ' V', this.I[index] + ' A', this.VACG[index] + ' V', this.IACG[index] + ' A']);
    }
    setTimeout(() => {
      this.excelService.exportAsExcelFile(this.Toexcel, this.Toexcel2, 'Hoja 1', 'Hoja 2', 'Reporte de Datos Instalación Solar Fotovoltaica' + moment().format('_MM_DD_YY-h_mm_ss'));
      setTimeout(() => {
        this.Toexcel = [['HORA', 'Voltaje Panel', 'Corriente Panel', 'Voltaje Bateria', 'Corriente Bateria', 'Voltaje de Salida', 'Corriente de Salida']];
        this.Toexcel2 = [];
      }, 10000);
    }, 500);
  }

  generarGrafico3() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 300);
    const canvas = document.getElementById('History3') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // this.chart3 = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    //     datasets: [{
    //       label: 'Corriente',
    //       yAxisID: 'I',
    //       backgroundColor: 'rgba(30, 68, 210)',
    //       borderColor: 'rgba(30, 68, 210)',
    //       data: this.I
    //     },
    //     {
    //       label: 'Voltaje',
    //       yAxisID: 'V',
    //       backgroundColor: 'rgba(38, 185, 154, 0.4)',
    //       borderColor: 'rgba(38, 185, 154)',
    //       data: this.V,
    //       type: 'line'
    //     }
    //     ]
    //   },
    //   options: {
    //     scales: {
    //       yAxes: [{
    //         id: 'V',
    //         type: 'linear',
    //         position: 'left',
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 30
    //         }
    //       }, {
    //         id: 'I',
    //         type: 'linear',
    //         position: 'right',
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 20
    //         }
    //       }]
    //     }
    //   }
    // });
  }


  generarGrafico() {

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 300);
    const canvas = document.getElementById('History') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // this.chart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    //     datasets: [{
    //       label: 'Potencia Consumida',
    //       yAxisID: 'PTC',
    //       backgroundColor: 'rgba(41, 177, 50, 0.1)',
    //       borderColor: 'rgba(41, 177, 50)',
    //       data: this.Pconsumida,
    //       type: 'line'
    //     },
    //     {
    //       label: 'Corriente AC',
    //       yAxisID: 'IAC',
    //       backgroundColor: 'rgba(104, 41, 177)',
    //       borderColor: 'rgba(104, 41, 177)',
    //       data: this.IACG
    //     },
    //     {
    //       label: 'Voltaje AC',
    //       yAxisID: 'VAC',
    //       backgroundColor: 'rgba(255, 206, 0, 0.3)',
    //       borderColor: 'rgba(255, 206, 0)',
    //       data: this.VACG,
    //       type: 'line'
    //     }
    //     ]
    //   },
    //   options: {
    //     scales: {
    //       yAxes: [{
    //         id: 'VAC',
    //         type: 'linear',
    //         position: 'left',
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 125
    //         }
    //       }, {
    //         id: 'IAC',
    //         type: 'linear',
    //         position: 'right',
    //         ticks: {
    //           suggestedMin: 0,
    //           suggestedMax: 4
    //         }
    //       },
    //       {
    //         id: 'PTC',
    //         type: 'linear',
    //         position: 'left'
    //       }]
    //     }
    //   }
    // });
    setTimeout(() => {
      //window.scrollTo(0, document.body.scrollHeight);
    }, 300);
  }

  isMap() {
    if (this.mapActive == false) {
      this.mapActive = true;
      this.list1Active = false
      this.informesActive = false;
      this.adminActive = false;
      this.listActive = false;
      this.historyActive = false;
    } else {
      this.mapActive = false;
    }
  }
  isDiagram() {
    if (this.diagramActive == false) {
      this.diagramActive = true;
      this.graphicActive = false;
      this.DiagActive = false;
    } else {
      this.diagramActive = false;
    }
  }
  isGraphic() {
    if (this.graphicActive == false) {
      this.graphicActive = true;
      this.diagramActive = false;
      this.DiagActive = false;
    } else {
      this.graphicActive = false;
    }
  }

  isDiag() {
    if (this.DiagActive == false) {
      this.DiagActive = true;
      this.diagramActive = false;
      this.graphicActive = false;
    } else {
      this.DiagActive = false;
    }
  }

  isInforme() {
    if (this.informesActive == false) {
      this.mapActive = false;
      this.informesActive = true;
      this.list1Active = false;
      this.adminActive = false;
      this.listActive = false;
      this.Graficas = true;
      this.historyActive = false;
      const pdata4 = { option: 'enelardia' };
      this.global.consultarvv(pdata4, (err3, response3) => {
        if (err3 == null) {
          console.log('DATA', response3);
          this.DIA = response3;
          this.ProcesarData3();
          const pdata2 = { option: 'enelartipo1' };
          this.global.consultarvv(pdata2, (err1, response1) => {
            if (err1 == null) {
              console.log('DATA', response1);
              this.Tipo1 = response1;
              const pdata3 = { option: 'enelartipo2' };
              this.global.consultarvv(pdata3, (err2, response2) => {
                if (err2 == null) {
                  console.log('DATA', response2);
                  this.Tipo2 = response2;
                  this.ProcesarData2();
                  const pdata1 = { option: 'enelarrecargas' };
                  this.global.consultarvv(pdata1, (err, response) => {
                    if (err == null) {
                      console.log('DATA', response);
                      this.Datos = response;
                      this.ProcesarData();
                    }
                  });
                  this.ColorLabels();
                }
              });
            }
          });
        }
      });

    } else {
      this.informesActive = false;
    }
  }
  isHistory() {
    if (this.historyActive == false) {
      this.spinner.show();
      setTimeout(() => {
        this.historyActive = true;
        this.mapActive = false;
        this.adminActive = false;
        this.informesActive = false;
        this.list1Active = false;
        this.listActive = false;
        const pdata2 = { option: 'inforec' };
        $.ajax({
          data: pdata2, cache: false, dataType: 'json', type: 'post',
          url: 'https://www.php.engenius.com.co/Database.php',
          success(data, textStatus, jQxhr) {
            console.log('Datos Recibidos:', data);
          },
          error(jqXhr, textStatus, errorThrown) {
            console.log(JSON.stringify(errorThrown));
          }
        }).then((response) => {
          console.log('Data Recargas', response);
          this.DataRecargas = response;
          $(function () {
            var table = this.table = $('#listHistoryTable').DataTable({
              'responsive': true,
              'destroy': true,
              'retrieve': true,
              'paging': true,
              //'orderCellsTop': true,
              'fixedHeader': false,
              "columns": [
                { title: 'Id Recarga' },
                { title: 'Usuario' },
                { title: 'Valor' },
                { title: 'Recaudador' },
                { title: 'Fecha' },
              ],
              'pagingType': 'numbers',
              'language': {
                'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
              },
              'buttons': [
                'excel', 'pdf', 'print'
              ]
            });
            $('#listHistoryTable thead tr').clone(true).appendTo('#listHistoryTable thead');
            $('#listHistoryTable thead tr:eq(1) th').each(function (i) {
              var title = $(this).text();
              $(this).html('<input type="text" placeholder="Search ' + title + '" />');
              $('input', this).on('keyup change', function () {
                if (table.column(i).search() !== this.value) {
                  table.column(i)
                    .search(this.value)
                    .draw();
                }
              });
            });
          });
        });
        this.spinner.hide();
      }, 10000);
    } else {
      this.historyActive = false;
    }
  }
  isFirst() {
    if (this.firstActive == false) {
      this.firstActive = true;
      this.scndActive = false;
      this.scndRate = false;
    } else {
      this.firstActive = false;
    }
  }
  isSecond() {
    if (this.scndActive == false) {
      this.scndActive = true;
      this.firstActive = false;
      this.firstRate = false;
    } else {
      this.scndActive = false;
    }
  }
  ObtenerDatosss() {
    this.VECTOR = this.InstalacionE.split(',');
    console.log(this.VECTOR);
    const pdata2 = { option: 'tagdata', ide: this.VECTOR[0] };
    console.log('TAGDATA', pdata2);
    this.global.consultarEnelar(pdata2, (err2, response) => {
      this.DataHistory = response;
      console.log('DATA HISTORY', response);
      console.log('Data', response[0][0].split(','));
      this.TagData = (response[0][0]).split(',');
      this.ObtenerHistoricos();
    });
    /* const pdata3 = { option: 'timelinedata', ide: this.VECTOR[0] };
    console.log('TIMELINE ------>', pdata3);
    this.global.consultarEnelar(pdata3, (err3, response3) => {
      console.log('DATA HISTORY TIMELINE', response3);
      let months:any[];
      for (let i = 0; i < response3.length; i++) {
        let actualDate = moment(response3[i][1]).format("DD/MM/YYYY");
        console.log('arr date -------->', actualDate.split('/'));
        console.log('month number', actualDate.split('/')[1]);
        console.log('month name', this.getMonth(actualDate.split('/')[1]));
        console.log('month and year ', this.getMonth(actualDate.split('/')[1]) + ' ' + actualDate.split('/')[2]);
        months.push(this.getMonth(actualDate.split('/')[1]) + ' ' + actualDate.split('/')[2]);
      }
      console.log(months);
      console.log('Data TIMELINE', response3[0][0].split(','));
    }); */
  }

  getMonth(month) {
    switch (month) {
      case '01':
        return 'Enero';
      case '02':
        return 'Febrero';
      case '03':
        return 'Marzo';
      case '04':
        return 'Abril';
      case '05':
        return 'Mayo';
      case '06':
        return 'Junio';
      case '07':
        return 'Julio';
      case '08':
        return 'Agosto';
      case '09':
        return 'Septiembre';
      case '10':
        return 'Octubre';
      case '11':
        return 'Noviembre';
      case '12':
        return 'Diciembre';
      default:
        break;
    }
  }
  ObtenerHistoricos() {
    this.ALERTA = false;
    this.Horas = new Array();
    const Hora0 = this.TagData[8].slice(0, 24);
    const Hora1 = this.TagData[8].slice(24, 32) + this.TagData[9].slice(0, 16);
    const Hora2 = this.TagData[9].slice(16, 32) + this.TagData[10].slice(0, 8);
    const Hora3 = this.TagData[10].slice(8, 32);
    const Hora4 = this.TagData[12].slice(0, 24);
    const Hora5 = this.TagData[12].slice(24, 32) + this.TagData[13].slice(0, 16);
    const Hora6 = this.TagData[13].slice(16, 32) + this.TagData[14].slice(0, 8);
    const Hora7 = this.TagData[14].slice(8, 32);
    const Hora8 = this.TagData[16].slice(0, 24);
    const Hora9 = this.TagData[16].slice(24, 32) + this.TagData[17].slice(0, 16);
    const Hora10 = this.TagData[17].slice(16, 32) + this.TagData[18].slice(0, 8);
    const Hora11 = this.TagData[18].slice(8, 32);
    const Hora12 = this.TagData[20].slice(0, 24);
    const Hora13 = this.TagData[20].slice(24, 32) + this.TagData[21].slice(0, 16);
    const Hora14 = this.TagData[21].slice(16, 32) + this.TagData[22].slice(0, 8);
    const Hora15 = this.TagData[22].slice(8, 32);
    const Hora16 = this.TagData[24].slice(0, 24);
    const Hora17 = this.TagData[24].slice(24, 32) + this.TagData[25].slice(0, 16);
    const Hora18 = this.TagData[25].slice(16, 32) + this.TagData[26].slice(0, 8);
    const Hora19 = this.TagData[26].slice(8, 32);
    const Hora20 = this.TagData[28].slice(0, 24);
    const Hora21 = this.TagData[28].slice(24, 32) + this.TagData[29].slice(0, 16);
    const Hora22 = this.TagData[29].slice(16, 32) + this.TagData[30].slice(0, 8);
    const Hora23 = this.TagData[30].slice(8, 32);
    this.SerialSTM = parseInt(this.TagData[56].slice(0, 18), 16);
    this.SerialRFID = this.VECTOR[0];
    this.Egen = parseInt(this.TagData[57].slice(0, 4), 16);
    this.Econ = parseInt(this.TagData[57].slice(4, 8), 16);
    this.FechaVen = parseInt(this.TagData[60].slice(4, 6), 16) + "/" + parseInt(this.TagData[60].slice(6, 8), 16) + "/" + parseInt(this.TagData[60].slice(8, 10), 16);
    this.Alarmas = parseInt(this.TagData[60].slice(20, 22), 16);
    this.UltimaEscritura = parseInt(this.TagData[61].slice(4, 6), 16) + "/" + parseInt(this.TagData[61].slice(2, 4), 16) + "/" + parseInt(this.TagData[61].slice(0, 2), 16) + " " + parseInt(this.TagData[61].slice(6, 8), 16) + ":" + parseInt(this.TagData[61].slice(8, 10), 16);
    this.FechaVal = parseInt(this.TagData[61].slice(24, 26), 16) + "/" + parseInt(this.TagData[61].slice(22, 24), 16) + "/" + parseInt(this.TagData[61].slice(20, 22), 16);
    console.log('IMPORTNTE ---------------->', this.TagData[61], '60', this.TagData[60], this.Alarmas, ':', this.Egen, ':', this.Econ, ':', this.FechaVen);
    this.Mifare = (this.Alarmas & 16) >> 4;
    this.Mfallo = (this.Alarmas & 8) >> 3;
    this.Battery = (this.Alarmas & 4) >> 2;
    this.Puerta = (this.Alarmas & 2) >> 1;
    this.Recarga = (this.Alarmas & 1) >> 0;

    console.log('ALARMASSS --------------->', this.Mifare, this.Mfallo, this.Battery, this.Puerta, this.Recarga);
    console.log('Hora0', Hora0);
    console.log('Hora1', Hora1);
    console.log('Hora2', Hora2);
    console.log('Hora3', Hora3);
    console.log('Hora4', Hora4);
    console.log('Hora5', Hora5);
    console.log('Hora6', Hora6);
    console.log('Hora7', Hora7);
    console.log('Hora8', Hora8);
    console.log('Hora9', Hora9);
    console.log('Hora10', Hora10);
    console.log('Hora11', Hora11);
    console.log('Hora12', Hora12);
    console.log('Hora13', Hora13);
    console.log('Hora14', Hora14);
    console.log('Hora15', Hora15);
    console.log('Hora16', Hora16);
    console.log('Hora17', Hora17);
    console.log('Hora18', Hora18);
    console.log('Hora19', Hora19);
    console.log('Hora20', Hora20);
    console.log('Hora21', Hora21);
    console.log('Hora22', Hora22);
    console.log('Hora23', Hora23);
    this.Horas[0] = Hora0.match(/.{1,2}/g);
    this.Horas[1] = Hora1.match(/.{1,2}/g);
    this.Horas[2] = Hora2.match(/.{1,2}/g);
    this.Horas[3] = Hora3.match(/.{1,2}/g);
    this.Horas[4] = Hora4.match(/.{1,2}/g);
    this.Horas[5] = Hora5.match(/.{1,2}/g);
    this.Horas[6] = Hora6.match(/.{1,2}/g);
    this.Horas[7] = Hora7.match(/.{1,2}/g);
    this.Horas[8] = Hora8.match(/.{1,2}/g);
    this.Horas[9] = Hora9.match(/.{1,2}/g);
    this.Horas[10] = Hora10.match(/.{1,2}/g);
    this.Horas[11] = Hora11.match(/.{1,2}/g);
    this.Horas[12] = Hora12.match(/.{1,2}/g);
    this.Horas[13] = Hora13.match(/.{1,2}/g);
    this.Horas[14] = Hora14.match(/.{1,2}/g);
    this.Horas[15] = Hora15.match(/.{1,2}/g);
    this.Horas[16] = Hora16.match(/.{1,2}/g);
    this.Horas[17] = Hora17.match(/.{1,2}/g);
    this.Horas[18] = Hora18.match(/.{1,2}/g);
    this.Horas[19] = Hora19.match(/.{1,2}/g);
    this.Horas[20] = Hora20.match(/.{1,2}/g);
    this.Horas[21] = Hora21.match(/.{1,2}/g);
    this.Horas[22] = Hora22.match(/.{1,2}/g);
    this.Horas[23] = Hora23.match(/.{1,2}/g);
    console.log('Horas Vector', this.Horas);
    this.ValoresHoras = new Array();
    for (let i = 0; i < this.Horas.length; i++) {
      let V = "0";
      let I = "0";
      let VP = "0";
      let IP = "0";
      let VAC = "0";
      let IAC = "0";
      if (i < 6 || i > 18) {
        V = this.Horas[i][1] + '' + this.Horas[i][0];
        I = this.Horas[i][3] + '' + this.Horas[i][2];
        VP = '0';
        IP = '0';
        VAC = this.Horas[i][9] + '' + this.Horas[i][8];
        IAC = this.Horas[i][11] + '' + this.Horas[i][10];
      } else {
        V = this.Horas[i][1] + '' + this.Horas[i][0];
        I = this.Horas[i][3] + '' + this.Horas[i][2];
        VP = this.Horas[i][5] + '' + this.Horas[i][4];
        IP = this.Horas[i][7] + '' + this.Horas[i][6];
        VAC = this.Horas[i][9] + '' + this.Horas[i][8];
        IAC = this.Horas[i][11] + '' + this.Horas[i][10];
      }
      const objt = {
        V: parseInt(V, 16),
        I: parseInt(I, 16),
        VP: parseInt(VP, 16),
        IP: parseInt(IP, 16),
        VAC: parseInt(VAC, 16),
        IAC: parseInt(IAC, 16)
      };
      this.V[i] = (parseInt(V, 16)) / 100;
      this.I[i] = (parseInt(I, 16)) / 10;
      this.VP[i] = (parseInt(VP, 16)) / 100;
      this.IP[i] = (parseInt(IP, 16)) / 10;
      this.VACG[i] = (parseInt(VAC, 16)) / 100;
      this.IACG[i] = (parseInt(IAC, 16)) / 10;
      this.Pconsumida[i] = this.VACG[i] * this.IACG[i];
      this.Pgenerada[i] = this.VP[i] * this.IP[i];
      this.ValoresHoras[i] = objt;
      if (this.V[i] <= 24) {
        this.ALERTA = true;
      }
    }
    console.log(this.ValoresHoras);
    this.ObtDatosAux();
  }
  ObtDatosAux() {
    const data = (this.TagData[58]).match(/.{1,4}/g);
    const V = (parseInt(data[0], 16) / 100);
    const I = (parseInt(data[1], 16) / 10);
    const VPV = (parseInt(data[2], 16) / 100);
    const IPV = (parseInt(data[3], 16) / 10);
    const AC_OUT_V = (parseInt(data[4], 16) / 100);
    const V_ADC = (parseInt(data[5], 16) / 100);
    const AC_OUT_V_ADC = (parseInt(data[6], 16) / 100);
    const AC_OUT_I_ADC = (parseInt(data[7], 16) / 10);
    this.ValInstantaneo = { V, I, VPV, IPV, AC_OUT_V, V_ADC, AC_OUT_V_ADC, AC_OUT_I_ADC };
    console.log('V', V, data[0]);
    console.log('I', I, data[1]);
    console.log('VPV', VPV, data[2]);
    console.log('IPV', IPV, data[3]);
    console.log('AC_OUT_V', AC_OUT_V, data[4]);
    console.log('V_ADC', V_ADC, data[5]);
    console.log('AC_OUT_V_ADC', AC_OUT_V_ADC, data[6]);
    console.log('AC_OUT_I_ADC', AC_OUT_I_ADC, data[7]);
    const Data2 = (this.TagData[57]).match(/.{1,8}/g);
    this.ContGen = parseInt(Data2[0], 16);
    this.ContCon = parseInt(Data2[1], 16);
    this.bandera = true;
    if (this.ALERTA == true) {
      this.Bandera2 = true;
    } else {
      this.Bandera2 = true;
    }
  }
  ObtenerDatosss2() {
    console.log(this.DatosSel);
    this.Bandera2 = false;
    console.log('SELCCIONADA', this.DatosSel);
    this.TagData = (this.DatosSel).split(',');
    console.log(this.TagData);
    setTimeout(() => {
      this.ObtenerHistoricos();
    }, 300);
    this.Bandera2 = false;
  }
  Cambio() {
    this.bandera = false;
    this.Instalacion2 = this.VECTOR[0];
    console.log('Instalacion: ', this.VECTOR[0]);
  }

  isList() {
    if (this.listActive == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.list1Active = false;
      this.adminActive = false;
      this.listActive = true;
      this.historyActive = false
    } else {
      this.listActive = false;
    }
  }
  isAdmin() {
    if (this.adminActive == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.listActive = false;
      this.list1Active = false;
      this.adminActive = true;
      this.historyActive = false;
    } else {
      this.adminActive = false;
    }
  }
  isUser() {
    if (this.list1Active == false) {
      this.mapActive = false;
      this.informesActive = false;
      this.adminActive = false;
      this.listActive = false;
      this.list1Active = true;
      this.historyActive = false
      $(function () {
        this.table = $('#list1Table').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
    } else {
      this.list1Active = false;
    }
  }
  ColorLabels() {
    this.color = 'rgb(102, 102, 102, 0.8)';
    this.colorg = 'rgb(102, 102, 102, 0.2)';
  }



  ProcesarData() {
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.Datos.length; index++) {
      series.push(this.Datos[index][0]);
      labels.push(this.Datos[index][2] + '-' + this.Datos[index][3]);
      background.push('rgba(153, 102, 255, 0.2)');
      color.push('rgba(153, 102, 255, 1');
    }
    this.DataCue = {
      'labels': labels,
      'series': series
    };
    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    // const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas',
    //       data: series,
    //       backgroundColor: background,
    //       borderColor: color,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     scales: {
    //       xAxes: [{
    //         gridLines: {
    //           color: this.colorg, // Eje x color verde
    //         },
    //         ticks: {
    //           fontColor: this.color,
    //         },

    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {

    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           display: true,
    //           labelString: 'Número de Recargas',
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //           display: false
    //         }
    //       }]
    //     }
    //   }
    // });
  }


  ProcesarData3() {
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.DIA.length; index++) {
      series.push(this.DIA[index][0]);
      labels.push('Día ' + this.DIA[index][2]);
      background.push('rgba(75, 192, 192, 0.2)');
      color.push('rgba(75, 192, 192, 1)');
    }
    this.DataCue = {
      'labels': labels,
      'series': series
    };
    const canvas = document.getElementById('myChart3') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas',
    //       data: series,
    //       backgroundColor: background,
    //       borderColor: color,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     maintainAspectRatio: false,
    //     responsive: true,
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //         },
    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Día del Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         gridLines: {
    //           color: this.colorg,
    //           display: false
    //         },
    //         ticks: {
    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Número de Recargas'
    //         },
    //       }]
    //     }
    //   }
    // });
  }


  ProcesarData2() {
    this.ColorLabels();
    const series = new Array();
    const labels = new Array();
    const color = new Array();
    const background = new Array();
    this.ColorLabels();
    for (let index = 0; index < this.Tipo1.length; index++) {
      series.push(this.Tipo1[index][0]);
      labels.push(this.Tipo1[index][2] + '-' + this.Tipo1[index][3]);
      background.push('rgba(255, 99, 132, 0.2)');
      color.push('rgba(255, 99, 132, 1)');
    }
    const series2 = new Array();
    const labels2 = new Array();
    const color2 = new Array();
    const background2 = new Array();
    for (let index = 0; index < this.Tipo2.length; index++) {
      series2.push(this.Tipo2[index][0]);
      labels2.push(this.Tipo2[index][2] + '-' + this.Tipo2[index][3]);
      background2.push('rgba(54, 162, 235, 0.2)');
      color2.push('rgba(54, 162, 235, 1)');
    }
    const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // let myChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Recargas $20.000',
    //       data: series,
    //       backgroundColor:
    //         background
    //       ,
    //       borderColor:
    //         color
    //       ,
    //       borderWidth: 1
    //     },
    //     {
    //       label: 'Recargas $30.000',
    //       data: series2,
    //       backgroundColor:
    //         background2
    //       ,
    //       borderColor:
    //         color2
    //       ,
    //       borderWidth: 1
    //     }]
    //   },
    //   options: {
    //     legend: {
    //       labels: {
    //         fontColor: this.color,
    //       }
    //     },
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //         },
    //         display: true,
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Mes'
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {
    //           fontColor: this.color,
    //           beginAtZero: true
    //         },
    //         scaleLabel: {
    //           fontColor: this.color,
    //           display: true,
    //           labelString: 'Número de Recargas'
    //         },
    //         gridLines: {
    //           color: this.colorg,
    //           display: false,
    //         },
    //       }]
    //     }
    //   }
    // });
    // myChart.update();
  }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  rateAble(type: number) {
    switch (type) {
      case 1:
        this.firstRate = !this.firstRate;
        this.rate2 = null;
        break;
      case 2:
        this.scndRate = !this.scndRate;
        this.rate1 = null;
        break;

      default:
        break;
    }
  }
  actionRate(btn: number) {
    switch (btn) {
      case 1:
        this.firstRate = false;
        this.rate1 = null;
        break;
      case 2:
        console.log(parseInt(this.rate1));
        if (parseInt(this.rate1) < 1000 || parseInt(this.rate1) > 51000) {
          this.alert.descErr('El valor no puede ser menor a $1.000 o mayor a $51.000. Intente nuevamente');
          this.rate1 = null;
          this.firstRate = false;
        } else {
          Swal.fire({
            title: '<strong>Está por cambiar la tarifa 1 ¿Desea continuar?</strong>',
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#093579',
            cancelButtonText: 'Cancelar',
            cancelButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              this.rate1 = this.rate1.toString().split('.').join('');
              const query = 'UPDATE db_proyectosfv.TopupTypes SET Topup_Type_Value = \'' + this.rate1 + '\'  WHERE Topup_Type_Id = 1;';
              const pdata1 = { option: 'insertar3', texto: query };
              this.global.consultar(pdata1, async (err, response) => {
                console.log(await response, query);
                if (err == null && await response == true) {
                  this.alert.descValid('Tarifa 1 se actualiza correctamente!');
                  this.rate1 = null;
                  this.firstRate = false;
                  this.ngOnInit();
                }
              });
            } else {
              this.rate1 = null;
              this.firstRate = false;
              this.ngOnInit();
            }
          });
        }
        break;
      case 3:
        this.scndRate = false;
        this.rate2 = null;

        break;
      case 4:
        if (parseInt(this.rate2) < 1000 || parseInt(this.rate2) > 51000) {
          this.alert.descErr('El valor no puede ser menor a $1.000 o mayor a $51.000. Intente nuevamente');
          this.scndRate = false;
          this.rate2 = null;
        } else {
          Swal.fire({
            title: '<strong>Está por cambiar la tarifa 2 ¿Desea continuar?</strong>',
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonColor: '#093579',
            cancelButtonText: 'Cancelar',
            cancelButtonAriaLabel: 'Thumbs up, great!',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              this.rate2 = this.rate2.toString().split('.').join('');
              const query = 'UPDATE db_proyectosfv.TopupTypes SET Topup_Type_Value = \'' + this.rate2 + '\'  WHERE Topup_Type_Id = 2;';
              const pdata1 = { option: 'insertar3', texto: query };
              this.global.consultar(pdata1,async  (err, response) => {
                console.log(await response, query);
                if (err == null && await response == true) {
                  this.alert.descValid('Tarifa 2 se actualiza correctamente!');
                  this.rate2 = null;
                  this.scndRate = false;
                  this.ngOnInit();
                }
              });
            } else {
              this.rate2 = null;
              this.scndRate = false;
              this.ngOnInit();
            }
          });
        }
        break;

      default:
        break;
    }
  }
  splitInDots(input) {
    let value = input.value,
      plain = this.plainNumber(value),
      reversed = this.reverseNumber(plain),
      reversedWithDots = reversed.match(/.{1,3}/g).join('.'),
      normal = this.reverseNumber(reversedWithDots);
    input.value = normal;
  }
  reverseNumber(input) {
    return [].map.call(input, function (x) {
      return x;
    }).reverse().join('');
  }
  plainNumber(number) {
    return number.split('.').join('');
  }

}
