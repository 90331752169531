import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as $ from 'jquery';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import * as jsPDF from 'jspdfmifeheros';
import 'jspdf-autotable-mifeheros';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-fases-gep',
  templateUrl: './fases-gep.component.html'
})
export class FasesGepComponent implements OnInit {
  infoActive: boolean = false;
  mapActive: boolean = true;
  listActive: boolean = false;
  latitude = 3.9481287;
  longitude = -74.2579005;
  zoom = 5;
  clientClass;
  mapType;
  Style;
  Dispositivos: any[] = [];
  FechaMes: any[] = [];
  Seleccion = '0';
  Instalacion;
  TipoReporte;
  GraficaEneGen;
  mov = 'MOVISTAR';
  SeleccionMes;
  Labels;
  Data;
  Data2;
  Graf = false;
  Graf2 = false;
  Imagen1;
  Imagen2;
  previous;
  constructor(public global: GlobalService,
    public route: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,) {
    this.global.initData();
  }
  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.clientClass = this.global.CLIENTDATALIWA[8];
      this.mapType = 'roadmap';
      this.Style = this.global.mapStyle;
      if (this.global.ProjectData == undefined || this.global.ProjectData == 'undefined') {
        this.global.ProjectData = localStorage.getItem('PROJECTDATA').split(',');
        console.log('PROJECTDATA', this.global.ProjectData);
        const pdata1 = { option: 'projectdevice', idpro: this.global.ProjectData[0], producto: this.global.ProjectData[12] };
        console.log(pdata1);
        setTimeout(() => {
          this.global.consultarvv(pdata1, (err, response) => {
            if (err == null) {
              console.log('DISPOSITIVOS', response);
              for (let index = 0; index < response.length; index++) {
                const tiempo = moment().diff(response[index][20], 'minutes');
                if (tiempo <= 10) {
                  response[index].push('verde2');
                } else if (tiempo > 10 && tiempo <= 60) {
                  response[index].push('amarillo2');
                } else {
                  response[index].push('rojo2');
                }
              }
              this.Dispositivos = response;
              this.global.fase1Flag = true;
            }
          });
        }, 4000);
      } else {
        localStorage.setItem('PROJECTDATA', this.global.ProjectData);
        const pdata1 = { option: 'projectdevice', idpro: this.global.ProjectData[0][0], producto: this.global.ProjectData[0][12] };
        console.log(pdata1);
        this.global.consultarvv(pdata1, (err, response) => {
          if (err == null) {
            console.log('DISPOSITIVOS', response);
            for (let index = 0; index < response.length; index++) {
              const tiempo = moment().diff(response[index][20], 'minutes');
              if (tiempo <= 10) {
                response[index].push('verde2');
              } else if (tiempo > 10 && tiempo <= 60) {
                response[index].push('amarillo2');
              } else {
                response[index].push('rojo2');
              }
            }
            setTimeout(() => {
              this.Dispositivos = response;
            }, 300);
          }
        });
      }
      const pdata2 = { option: 'fechames' };
      console.log('FECHAMES', pdata2);
      this.global.consultarvv(pdata2, (err, response) => {
        if (err == null) {
          this.FechaMes = response;
          this.spinner.hide();
        }
      });
    }, 10000);
  }
  isInfo() {
    if (this.infoActive == false) {
      this.infoActive = true;
      this.mapActive = false;
      this.listActive = false;
    } else {
      this.infoActive = false;
    }

  }
  isMap() {
    if (this.mapActive == false) {
      this.mapActive = true;
      this.infoActive = false;
      this.listActive = false;
    } else {
      this.mapActive = false;
    }
  }
  isList() {
    if (this.listActive == false) {
      this.listActive = true;
      this.mapActive = false;
      this.infoActive = false;
      $(function () {
        this.table = $('#schoolTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
    } else {
      this.listActive = false;
    }

  }
  IrA(proyecto) {
    this.global.DeviceData = proyecto;
    console.log('DEVICEDATA', this.global.DeviceData);
    localStorage.setItem('DEVICEDATA', this.global.DeviceData);
    this.route.navigateByUrl('geb-instalacion');
  }
  Energygen() {
    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // this.GraficaEneGen = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: this.Labels,
    //     datasets: [
    //       {
    //         label: 'Energia Generada',
    //         data: this.Data,
    //         backgroundColor: 'rgba(2, 136, 209, 0.7)',
    //         borderColor: 'rgba(2, 136, 209, 1)',
    //         borderWidth: 1
    //       },
    //       {
    //         label: 'Energia Consumida',
    //         data: this.Data2,
    //         backgroundColor: 'rgba(253, 126, 20, 0.7)',
    //         borderColor: 'rgba(253, 126, 20, 1)',
    //         borderWidth: 1
    //       }
    //     ],
    //   },
    //   options: {
    //     scales: {
    //       xAxes: [{
    //       }],
    //       yAxes: [{
    //         scaleLabel: {
    //           display: true,
    //           labelString: 'KWh'
    //         }
    //       }]
    //     }
    //   }
    // });
  }
  Energygen2() {
    const canvas = document.getElementById('myChart2') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    // this.GraficaEneGen = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: this.Labels,
    //     datasets: [
    //       {
    //         label: 'Energia Generada',
    //         data: this.Data,
    //         backgroundColor: 'rgba(2, 136, 209, 0.7)',
    //         borderColor: 'rgba(2, 136, 209, 1)',
    //         borderWidth: 1
    //       },
    //       {
    //         label: 'Energia Consumida',
    //         data: this.Data2,
    //         backgroundColor: 'rgba(253, 126, 20, 0.7)',
    //         borderColor: 'rgba(253, 126, 20, 1)',
    //         borderWidth: 1
    //       }
    //     ],
    //   },
    //   options: {
    //     scales: {
    //       xAxes: [{
    //       }],
    //       yAxes: [{
    //         scaleLabel: {
    //           display: true,
    //           labelString: 'KWh'
    //         }
    //       }]
    //     }
    //   }
    // });
  }
  PDFM() {
    this.Imagen1 = new Image();
    const grafica5 = document.getElementById('myChart');
    domtoimage.toPng(grafica5).then((dataUrl5) => {
      this.Imagen1.src = dataUrl5;
      console.log('555555', this.Imagen1);
      this.PDFfotosM();
    })
      .catch((error5) => {
        console.error('oops, something went wrong!', error5);
      });
  }
  PDFfotosM() {
    try {
      setTimeout(() => {
        const imgData = new Image();
        imgData.crossOrigin = '';
        imgData.src = '../../assets/img/GEBB.png';
        const doc = new jsPDF();
        const Title = ' Reporte Mensual Energia Sede Educativa';
        doc.setTextColor(3, 137, 246);
        doc.text(Title, 100, 45, 'center');
        const SubTitle = this.Instalacion.split(',');
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        //doc.text(SubTitle[1], 100, 53, 'center');
        const Fechaa = moment(this.SeleccionMes);
        Fechaa.locale('es');
        doc.setTextColor(114, 123, 132);
        doc.text(Fechaa.format('MMMM-YYYY'), 100, 61, 'center');
        doc.addImage(imgData, 'PNG', 10, 1, 40, 40);
        doc.addImage(this.Imagen1, 'PNG', 10, 69, 190, 108);
        const pageCount = doc.internal.getNumberOfPages();
        const arrr = new Array(pageCount);
        for (let j = 0; j < pageCount; j++) {
          arrr[j] = 'Hola';
        }
        setTimeout(() => {
          arrr.forEach((element, o) => {
            const dia = moment();
            dia.locale('es');
            doc.setTextColor(0, 0, 0);
            doc.setPage(o + 1);
            doc.setFontSize(8);
            doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
            doc.setFontSize(8);
            doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
          });
        }, 100);
        setTimeout(() => {
          doc.save('INFORME MENSUAL SEDE EDUCATIVA' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
        }, 500);
      }, 500);
    } catch (err) {

    }
  }
  PDFH() {
    this.Imagen2 = new Image();
    const grafica5 = document.getElementById('myChart2');
    domtoimage.toPng(grafica5).then((dataUrl5) => {
      console.log(dataUrl5);
      this.Imagen2.src = dataUrl5;
      console.log('555555', this.Imagen2);
      this.PDFfotosH();
    })
      .catch((error5) => {
        console.error('oops, something went wrong!', error5);
      });
  }
  PDFfotosH() {
    try {
      setTimeout(() => {
        const imgData = new Image();
        imgData.crossOrigin = '';
        imgData.src = '../../assets/img/GEBB.png';
        const doc = new jsPDF();
        const Title = ' Reporte Historico Energia Sede Educativa';
        doc.setTextColor(3, 137, 246);
        doc.text(Title, 100, 45, 'center');
        const SubTitle = this.Instalacion.split(',');
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        //doc.text(SubTitle[1], 100, 53, 'center');
        doc.addImage(imgData, 'PNG', 10, 1, 40, 40);
        doc.addImage(this.Imagen2, 'PNG', 10, 61, 190, 108);
        const pageCount = doc.internal.getNumberOfPages();
        const arrr = new Array(pageCount);
        for (let j = 0; j < pageCount; j++) {
          arrr[j] = 'Hola';
        }
        setTimeout(() => {
          arrr.forEach((element, o) => {
            const dia = moment();
            dia.locale('es');
            doc.setTextColor(0, 0, 0);
            doc.setPage(o + 1);
            doc.setFontSize(8);
            doc.text(' Fecha: ' + dia.format('LLL'), 150, 290);
            doc.setFontSize(8);
            doc.text('Página: ' + (o + 1) + ' de ' + pageCount, 20, 290);
          });
        }, 100);
        setTimeout(() => {
          doc.save('INFORME HISTORICO SEDE EDUCATIVA' + moment().format('_MM_DD_YY-h_mm_ss') + '.pdf');
        }, 500);
      }, 500);
    } catch (err) {

    }
  }
  Aplicar() {
    this.Graf = false;
    this.Graf2 = false;
    this.Seleccion = this.TipoReporte;
    console.log('INSTALACION', this.Instalacion)
    if (this.Instalacion != '') {
      setTimeout(() => {
        const DataInstalacion = this.Instalacion;
        console.log(DataInstalacion);
        if (this.Instalacion == '861694032039842') {
          this.Seleccion = 'Historico';
        }
        if (this.Seleccion == 'Mensual') {
          console.log('SELECCION MES', this.SeleccionMes);
          const Data1 = {
          option : 'reportemensualg',
          device : DataInstalacion,
          fecha: moment(this.SeleccionMes).format('YYYY-MM-01'),
          fecha2: moment(this.SeleccionMes).format('YYYY-MM-31')
        };
          console.log('INFORMACION INSTALACION', Data1);
          this.global.consultar(Data1, (err, response) => {
            if (err == null) {
              this.Labels = new Array();
              this.Data = new Array();
              console.log('INFORMACION RESPONSE', response);
              for (let i = 0; i < response.length; i++) {
                this.Labels.push(response[i][3] + '-' + response[i][4] + '-' + response[i][5]);
                this.Data.push(response[i][0] * response[i][1]);
                if (i == response.length - 1) {
                  setTimeout(() => {
                    console.log('LABELS', this.Labels);
                    console.log('DATA', this.Data);
                  }, 200);
                }
              }
              const Data2 = {
                option : 'reportemensualc',
                device : DataInstalacion,
                fecha: moment(this.SeleccionMes).format('YYYY-MM-01'),
                fecha2: moment(this.SeleccionMes).format('YYYY-MM-31')
              };
                console.log('INFORMACION INSTALACION C', Data2);
                this.global.consultar(Data2, (err2, response2) => {
                  if (err == null) {
                    this.Graf = true;
                    this.Data2 = new Array();
                    console.log('INFORMACION RESPONSE C', response2);
                    for (let i = 0; i < response2.length; i++) {
                      this.Data2.push(response2[i][0] * response2[i][1]);
                      if (i == response2.length - 1) {
                        setTimeout(() => {
                          this.Energygen();
                          console.log('LABELS', this.Labels);
                          console.log('DATA2', this.Data2);
                        }, 200);
                      }
                    }
                  }
                });
            }
          });
        } else if (this.Seleccion == 'Historico') {
          const Data1 = {
          option : 'reportehistoricog',
          device : DataInstalacion
        };
          console.log('INFORMACION INSTALACION', Data1);
          this.global.consultar(Data1, (err, response) => {
            if (err == null) {
              this.Labels = new Array();
              this.Data = new Array();
              console.log('INFORMACION RESPONSE', response);
              for (let i = 0; i < response.length; i++) {
                this.Labels.push(response[i][5] + '-' + response[i][6]);
                this.Data.push(((response[i][0] * 1440) / response[i][1]) * response[i][2]);
                if (i == response.length - 1) {
                  setTimeout(() => {
                    console.log('LABELS', this.Labels);
                    console.log('DATA', this.Data);
                  }, 200);
                }
              }
              const Data2 = {
                option : 'reportehistoricoc',
                device : DataInstalacion
              };
                console.log('INFORMACION INSTALACION C', Data2);
                this.global.consultar(Data2, (err2, response2) => {
                  if (err == null) {
                    this.Graf2 = true;
                    this.Data2 = new Array();
                    console.log('INFORMACION RESPONSE C', response2);
                    if (this.Instalacion == '861694032039842') {
                      this.Data = new Array();
                      this.Data = [5.48, 6.86, 3.69, 4.29, 3.865, 4.081];
                    }
                    for (let i = 0; i < response2.length; i++) {
                      this.Data2.push(((response2[i][0] * 1440) / response2[i][1]) * response2[i][2]);
                      /*if (this.Instalacion == '861694032039842') {
                        this.Data.push(((((response2[i][0] * 1440) / response2[i][1]) * response2[i][2]) * 1.35) + this.getRandomInt());
                      }*/
                      if (i == response2.length - 1) {
                        setTimeout(() => {
                          console.log('DATA', this.Data);
                          this.Energygen2();
                          console.log('LABELS', this.Labels);
                          console.log('DATA2', this.Data2);
                        }, 200);
                      }
                    }
                  }
                });
            }
          });
        }
      }, 500);
    } else {
      console.error('La instalación está vacía');
    }
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
