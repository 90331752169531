import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/global.service';
import { Router } from "@angular/router";
import * as $ from 'jquery';

@Component({
  selector: 'app-tenderos',
  templateUrl: './tenderos.component.html',
  styleUrls: ['./tenderos.component.scss']
})
export class TenderosComponent implements OnInit {
  clientClass;
  Id_Encuesta;
  records = [];
  tableOn;
  Archivo;
  banderaFotos:boolean = false 
  flagS = false;
  constructor(private global: GlobalService, public ruta: Router, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show();
    window.localStorage.setItem("informacion encuesta", JSON.stringify([]));
    this.clientClass = this.global.CLIENTDATALIWA;
    const pdata = { option: 'insertar2', texto: `SELECT A.* FROM db_liwa.Tendero A WHERE (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.Id_Client = ${this.global.Id_Client} AND E.id_tipoProyecto = A.Id_Proyecto) AND A.isDelete = 0;`}
    this.global.consultar(pdata, (err4, response )=>{
     
      if(response.length > 0){
        this.records = response;
        this.records.map((i)=> {   
        let result = (  ( i.filter(contador => contador === "" || contador === "null").length / 30 ) * 100)
        let calculo = Math.round( result) + '%'
         i.push( calculo)             
        })
        console.log(this.records, 'este es el necesito ahorita')
        this.tableOn = true;
        this.spinner.hide()
        $(function () {
          this.table = $('#Tendero').DataTable({
            'responsive': false,
            'destroy': true,
            'retrieve': true,
            'paging': true,
            'info': false,
            'pagingType': 'numbers',
            'language': {
              'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
            }
          })
        })
      } else {
        this.spinner.hide()
      }
    })
  }

  semaforoFaltante(prams){
    // console.log(Number.parseInt(prams) ,'esewesetetetteett')
    if (Number.parseInt(prams) === 0) return 'badge  btn-custom3'
    else if (Number.parseInt(prams) <= 20) return 'badge badge-success'
    else if (Number.parseInt(prams) <= 40) return 'badge badge-leve'
    else if (Number.parseInt(prams) <= 60) return 'badge badge-warning'
    else return 'badge badge-danger'
  }
  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  cargarInfo(Id_Encuesta){
      this.spinner.show();
      // console.log(Id_Encuesta)
      const pdata = { option: 'insertar2', texto: "SELECT (SELECT `Nombre` FROM db_liwa.tipoProyecto WHERE `id_tipoProyecto` = A.`Id_Proyecto`),(SELECT `Nombre` FROM db_liwa.Seccionales WHERE `id_Seccional` = A.`id_Seccional`), Fecha, `Nombre_Completo`, `TipoDoc`, `Cedula`, `Telefono`, `Sexo`, `Correo`, `Ocupacion`, `Depto`, `Municipio`, `Vereda`, `Tipo`, `Celular`, `Smarth`, `Cuenta`, `Comisiones`, `Ganar`,`Id_Encuesta` from db_liwa.Tendero A WHERE Id_Encuesta = '"+Id_Encuesta+"'" };
            this.global.consultar(pdata, (err4, response )=>{
              if(response.length > 0){
                window.localStorage.setItem(
                  "informacion encuesta",
                  JSON.stringify(response[0],[Id_Encuesta])
                 )             
                   this.ruta.navigateByUrl("/detalle-tendero");
                   this.spinner.hide()
    
              } else {
                // falta una alerta aca 
                this.spinner.hide()
              }
            })

    
  }
}