import { Component, OnInit } from "@angular/core";
import { GlobalAlertService } from "src/app/global-alert.service";
import { GlobalService } from "../../global.service";
import { Router } from "@angular/router";
import * as moment from "moment";
import * as $ from "jquery";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { NgxSpinnerService } from "ngx-spinner";
// import { ScaleControlStyle } from "@agm/core";
// import { Model } from "echarts";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
})
export class CreateTicketComponent implements OnInit {
  product;
  sede;
  sedes: any[] = [];
  NameS;
  description;
  IdProduct;
  NickName;
  Importancia;
  banderaNombres: boolean = false;
  nombresIncidentes = Array([]);
  arregloBeneficiariosIncidentes = Array([]);
  productos: any[] = [];
  srcImg: any[] = [];
  imgsUrls: any[] = [];
  Evidence: boolean = false;
  contactName;
  contactPhone;
  USERDATALIWA;
  NameP;
  municipio;

  clientClass;
  benefits = new Array();
  beneFound: boolean = false;
  cedulaBeneficiario;
  idBenefit;
  nombre_relacionados: string = "";
  nameBenefit;
  ccBenefit;
  phoneBenefit;
  provBenefit;
  seccionalBenefit;
  projectBenefit;
  descriptionBenefit;
  seccionalBeneficiario: any[] = [];
  proyectos: any[] = [];
  idProyecto;
  seccionales: any[] = [];
  idSeccional;
  proysecOn: boolean = false;
  secciOn: boolean = false;
  idTicket;
  typePQRS;

  types = [
    { type: "Petición" },
    { type: "Queja" },
    { type: "Reclamo" },
    { type: "Sugerencia" },
  ];

  BeneficiarioBd;

  constructor(
    public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    public spinner: NgxSpinnerService
  ) {
    this.global.initData();
    const vari = localStorage.getItem("USERDATALIWA");
    this.USERDATALIWA = vari.split(",");
    // console.log('USERDATALIWA', this.USERDATALIWA);
    this.global.UserName = this.USERDATALIWA[3];
    this.global.Id_Usuario = this.USERDATALIWA[0];
  }

  ngOnInit(): void {
    this.spinner.show();
    window.localStorage.setItem("informacion beneficiario", JSON.stringify([]));
    this.spinner.hide();
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    // console.log(selectedFiles);
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imagen = new Image();
        imagen.src = event.target.result;
        this.srcImg[i] = imagen.src;
        // console.log(imagen.src);
      };
      reader.readAsDataURL(event.target.files[i]);
    }
    this.Evidence = true;
  }
  atras() {
    // window.history.back();
    this.ruta.navigateByUrl("/user-ticket");
  }
  IdP() {
    const pdata8 = { option: "IdP", producto: this.product };
    // console.log('producto', pdata8);
    this.global.consultar(pdata8, (err8, response8) => {
      // console.log('Importancia Producto', response8);
      this.Importancia = response8;
      // console.log('Impo', this.Importancia);
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
  loadPhoto(idTix) {
    if (this.Evidence == false) {
      this.alerta.descErr("Error al subir la foto, verifique nuevamente");
    } else {
      this.spinner.show();
      try {
        setTimeout(() => {
          let con1 = 0;
          let con2 = 0;

          for (let i = 0; i < this.srcImg.length; i++) {
            con1++;
            // console.log('Foto #' + i, this.srcImg[i]);
            const FOTOO = this.srcImg[i].slice(23, this.srcImg[i].length);
            // nombre de la imagen en el servidor this.Photo
            const imageName = i + "-" + moment().unix() + ".jpg";
            const imageBlob = this.dataURItoBlob(FOTOO);
            const imageFile = FOTOO;
            const data = new FormData();
            // console.log(imageFile, imageBlob);
            data.append("image", imageFile);
            data.append("nombre", imageName);
            data.append("ruta", idTix);
            $.ajax({
              url: "https://fotos.engenius.com.co/subir_archivo_TicketLW.php",
              type: "post",
              dataType: "json",
              data,
              processData: false,
              contentType: false,
            }).then((data1) => {
              // console.log(data, data1);
              this.imgsUrls[i] = data1;
              con2++;
              if (
                this.imgsUrls[i] === "BADEXT" ||
                this.imgsUrls[i] === null ||
                this.imgsUrls[i] === undefined ||
                this.imgsUrls[i] === "" ||
                this.imgsUrls[i] === "NULL"
              ) {
                this.alerta.descErr(
                  "Error al subir la foto, verifique nuevamente"
                );
              } else {
                const query =
                  "REPLACE INTO db_liwa.Ticket_Files (Id_Ticket,Name_File,Type_File,File,IsDelete,IsActive)" +
                  " VALUES ('" +
                  idTix +
                  "','" +
                  imageName +
                  "','" +
                  "jpg" +
                  "','" +
                  data1 +
                  "','" +
                  0 +
                  "','" +
                  1 +
                  "');";
                const pdata1 = { option: "insertar", texto: query };
                this.global.consultar(pdata1, (err, response) => {
                  // console.log(response, query);
                  if (err == null && response == true) {
                    // alertFunctions.UserSuccess();
                    // this.alerta.descValid('El ticket se creo con éxito, pero no lo se rick');
                    // this.alerta.descErr('Error al crear el ticket, verifique nuevamente que  no hayan campos vacío');
                    // console.log('hola domun')
                  } else {
                    // console.log('este es el que sale')
                    this.alerta.descErr(
                      "Error al crear el ticket, verifique nuevamente que no hayan campos vacíos"
                    );
                  }
                });
                // goto crear ticket
                if (con1 == con2 && con2 == this.srcImg.length) {
                  try {
                    setTimeout(() => {
                      // console.log('este es mi cosole bien binuto', this.descriptionBenefit )
                      if (
                        this.descriptionBenefit === undefined ||
                        this.typePQRS === undefined
                      ) {
                        // console.log('este es el menaje ganador')
                        this.alerta.descErr(
                          "Revise por favor que tenga descripcion y tipo "
                        );
                        this.spinner.hide();
                      } else {
                        const query =
                          "REPLACE INTO db_liwa.Tickets (Id_Tickets,Id_Usuario,Id_Statuses,Id_Beneficiario,Description,tipoTicket,Creation_Date,Encargado,IsDelete,IsActive, IsArchived, Id_Seccional)" +
                          " VALUES ('" +
                          idTix +
                          "','" +
                          this.global.Id_Usuario +
                          "','" +
                          1 +
                          "','" +
                          this.idBenefit +
                          "','" +
                          this.descriptionBenefit +
                          "', '" +
                          this.typePQRS +
                          "','" +
                          moment().format("YYYY-MM-DD HH:mm:ss") +
                          "','" +
                          "-" +
                          "','" +
                          0 +
                          "','" +
                          1 +
                          "','" +
                          0 +
                          "','" +
                          this.idSeccional +
                          "');";
                        const pdata1 = { option: "insertar", texto: query };
                        this.global.consultar(pdata1, (err, response) => {
                          // console.log(response, query);
                          if (err == null && response == true) {
                            // alertFunctions.UserSuccess();
                            const query2 =
                              "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)" +
                              " VALUES ('" +
                              idTix +
                              "','" +
                              "PQRS Creada" +
                              "','" +
                              moment().format("YYYY-MM-DD HH:mm:ss") +
                              "','" +
                              1 +
                              "','" +
                              0 +
                              "','" +
                              1 +
                              "');";
                            const pdata2 = {
                              option: "insertar",
                              texto: query2,
                            };
                            this.global.consultar(pdata2, (err2, response2) => {
                              // console.log(response2, query2);
                              if (err2 == null && response2 == true) {
                                // alertFunctions.UserSuccess();
                                setTimeout(() => {
                                  this.spinner.hide();
                                  this.alerta.descValid(
                                    "Nueva PQRS creada con éxito"
                                  );
                                  this.ruta.navigateByUrl("/user-ticket");
                                }, 1000);
                              } else {
                                this.alerta.descErr(
                                  "Revise por favor que no hayan campos vacíos"
                                );
                              }
                            });
                          } else {
                            this.alerta.descErr(
                              "Revise por favor que no hayan campos vacíos"
                            );
                            // console.log('este es el que sale')
                          }
                        });
                      }
                    }, 400);
                    this.enviarcorreo();
                  } catch (error) {
                    // console.log('error:', +error);
                  }
                }
              }
            });
          }
        }, 400);
      } catch (error) {
        // console.log(error);
      }
    }
  }
  crear() {
    this.spinner.show();
    const exMoment = this.global.Id_Usuario + "_" + moment.now();
    // console.log(exMoment);
    this.idTicket = exMoment;
    if (this.Evidence == false) {
      try {
        // console.log(this.idTicket);
        setTimeout(() => {
          if (
            this.descriptionBenefit === undefined ||
            this.typePQRS === undefined
          ) {
            // console.log(this.imgsUrls, this.srcImg)
            this.alerta.descErr("Revise por favor que no hay campos vacíos");
            this.spinner.hide();
          } else {
            // console.log(this.idTicket);
            const query =
              "REPLACE INTO db_liwa.Tickets (Id_Tickets,Id_Usuario,Id_Statuses,Id_Beneficiario,Description,tipoTicket,Creation_Date,Encargado,IsDelete,IsActive, IsArchived, Id_Seccional)" +
              " VALUES ('" +
              this.idTicket +
              "','" +
              this.global.Id_Usuario +
              "','" +
              1 +
              "','" +
              this.idBenefit +
              "','" +
              this.descriptionBenefit +
              "', '" +
              this.typePQRS +
              "','" +
              moment().format("YYYY-MM-DD HH:mm:ss") +
              "','" +
              "-" +
              "','" +
              0 +
              "','" +
              1 +
              "','" +
              0 +
              "','" +
              this.idSeccional +
              "');";
            const pdata1 = { option: "insertar", texto: query };
            this.global.consultar(pdata1, (err, response) => {
              // console.log(response, query);
              if (err == null && response == true) {
                // alertFunctions.UserSuccess();
                const query2 =
                  "REPLACE INTO db_liwa.Update_Tickets (Id_Ticket,Description,Date,Id_Statuses,IsDelete,IsActive)" +
                  " VALUES ('" +
                  this.idTicket +
                  "','" +
                  "PQRS Creada" +
                  "','" +
                  moment().format("YYYY-MM-DD HH:mm:ss") +
                  "','" +
                  1 +
                  "','" +
                  0 +
                  "','" +
                  1 +
                  "');";
                const pdata2 = { option: "insertar", texto: query2 };
                this.global.consultar(pdata2, (err2, response2) => {
                  // console.log(response2, query2);
                  if (err2 == null && response2 == true) {
                    // alertFunctions.UserSuccess();
                    setTimeout(() => {
                      this.spinner.hide();
                      this.alerta.descValid("Nueva PQRS creada con éxito");
                      this.ruta.navigateByUrl("/user-ticket");
                    }, 1000);
                  } else {
                    this.alerta.descErr(
                      "Revise por favor que no hay campos vacíos"
                    );
                  }
                });
              } else {
                this.alerta.descErr(
                  "Revise por favor que no hay campos vacíos"
                );
              }
            });
          }
        }, 400);
        this.enviarcorreo();
      } catch (error) {
        // console.log('error:', +error);
      }
    } else {
      this.loadPhoto(this.idTicket);
    }
  }
  enviarcorreo() {
    setTimeout(() => {
      const pdata9 = {
        option: "mail",
        ticket: this.idTicket,
        Seccional: this.seccionalBenefit,
        descr: this.descriptionBenefit,
        type: this.typePQRS,
        contacto: this.nameBenefit,
        tel: this.phoneBenefit,
        user: this.USERDATALIWA[8],
        municipio: this.provBenefit,
      };
      this.global.consultar(pdata9, (err2, response2) => {});
    }, 500);
  }
  searchBenefit(model) {
    this.spinner.show();
    const consultaBeneficiario = {
      option: "insertar2",
      // texto: `SELECT A.*, B.Id_Seccional,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = B.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = B.Id_Proyecto) AS Nombre_proyecto, B.Id_Proyecto FROM db_liwa.Beneficiario A INNER JOIN db_liwa.Beneficiario_Seccional B ON A.Id_Beneficiario = B.Id_Beneficiario WHERE  A.IsDelete=0 AND A.NumeroDoc = '${model}' OR A.Id_Beneficiario = '${model}' OR A.Id_Beneficiario IN (SELECT Id_Beneficiario FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Tickets = '${model}');`,
      // texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, A.Id_Proyecto FROM db_liwa.AOM A WHERE  A.IsDelete=0 AND A.Cedula = '${model}' OR A.NUI = '${model}' OR A.Id IN (SELECT Id_Beneficiario FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Tickets = '${model}');`
      texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Id_Cliente, A.Id_Proyecto FROM db_liwa.EncuestasAOM A WHERE (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.Id_Client = ${this.global.Id_Client} AND E.id_tipoProyecto = A.Id_Proyecto) AND A.IsDelete=0 AND A.Cedula = '${model}' OR A.NUI = '${model}' OR A.Id IN (SELECT Id_Beneficiario FROM db_liwa.Tickets_New WHERE IsDelete=0 AND Id_Tickets = '${model}');`,
    };
    console.log(
      "Search ->",
      consultaBeneficiario,
      "Lo que se consulta ->",
      model
    );
    if (isNaN(model)) {
      console.log("esta busqueda no tiene numeros");
      const pdataNombres = {
        option: "insertar2",
        texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Id_Cliente, A.Id_Proyecto FROM db_liwa.EncuestasAOM A INNER JOIN db_liwa.tipoProyecto C ON C.id_tipoProyecto = A.Id_Proyecto WHERE C.Id_Client = "${this.global.Id_Client}" AND A.Nombre_Completo LIKE '%${model}%' OR A.NUI LIKE '%${model}%';`,
      };
      console.log("Consulta del filtro->", pdataNombres);
      this.global.consultar(pdataNombres, (err, response) => {
        if (response.length === 1) {
          console.log("entro a donde era mi papa");
          this.benefits = response;
          this.global.informacionBeneficiario = this.benefits;
          window.localStorage.setItem(
            "informacion beneficiario",
            JSON.stringify(this.global.informacionBeneficiario)
          );
          this.global.informacionBeneficiario = JSON.parse(
            window.localStorage.getItem("informacion beneficiario")
          );
          this.ruta.navigateByUrl("/informacion-usuario");
          this.spinner.hide();
        } else if (response.length === 0) {
          const pdataTicket = {
            option: "insertar2",
            texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Id_Cliente, A.Id_Proyecto FROM db_liwa.EncuestasAOM A INNER JOIN db_liwa.Tickets_New T ON T.Id_Beneficiario = A.Id_Encuesta WHERE  A.NUI LIKE '%${model}%' OR T.Id_Tickets LIKE '%${model}%';`,
          };
          this.global.consultar(pdataTicket, (err, response) => {
            if (response.length === 1) {
              console.log("Pdata Ticket", response);
              this.benefits = response;
              this.global.informacionBeneficiario = this.benefits;
              window.localStorage.setItem(
                "informacion beneficiario",
                JSON.stringify(this.global.informacionBeneficiario)
              );
              this.global.informacionBeneficiario = JSON.parse(
                window.localStorage.getItem("informacion beneficiario")
              );
              this.ruta.navigateByUrl("/informacion-usuario");
              this.spinner.hide();
            } else if (response.length === 0) {
              const pdataNui = {
                option: "insertar2",
                texto: `SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Id_Cliente, A.Id_Proyecto FROM db_liwa.EncuestasAOM A WHERE  A.NUI LIKE '%${model}%';`,
              };
              this.global.consultar(pdataNui, (err, response) => {
                if (response.length === 1) {
                  console.log("Pdata Nui", response);
                  this.benefits = response;
                  this.global.informacionBeneficiario = this.benefits;
                  window.localStorage.setItem(
                    "informacion beneficiario",
                    JSON.stringify(this.global.informacionBeneficiario)
                  );
                  this.global.informacionBeneficiario = JSON.parse(
                    window.localStorage.getItem("informacion beneficiario")
                  );
                  this.ruta.navigateByUrl("/informacion-usuario");
                  this.spinner.hide();
                } else if (response.length > 1) {
                  this.alerta.descValid(
                    "Este nombre tiene mas de una coincidencia. Selecciona alguno de los usuarios por favor"
                  );
                  this.arregloBeneficiariosIncidentes = response;
                  for (let i = 0; i < response.length; i++) {
                    this.nombresIncidentes.push(response[i]);
                  }
                  console.log("PUSH Name", this.nombresIncidentes);
                  this.banderaNombres = true;
                  this.spinner.hide();
                } else {
                  this.alerta.descErr(
                    "No hemos encontrado ningun beneficiario Con este nombre, Lo sentimos mucho. puede intentar con la cedula el NUI o un numero de ticket asociado, Tenga en cuenta que NUI o Ticket no contenga espacios en vacíos"
                  );
                  this.spinner.hide();
                }
              });
            } else if (response.length > 1) {
              this.alerta.descValid(
                "Este nombre tiene mas de una coincidencia. Selecciona alguno de los usuarios por favor"
              );
              this.arregloBeneficiariosIncidentes = response;
              for (let i = 0; i < response.length; i++) {
                this.nombresIncidentes.push(response[i]);
              }
              console.log("PUSH Name", this.nombresIncidentes);
              this.banderaNombres = true;
              this.spinner.hide();
            } else {
              this.alerta.descErr(
                "No hemos encontrado ningun beneficiario Con este nombre, Lo sentimos mucho. puede intentar con la cedula el NUI o un numero de ticket asociado, Tenga en cuenta que NUI o Ticket no contenga espacios en vacíos"
              );
              this.spinner.hide();
            }
          });
        } else if (response.length > 1) {
          this.alerta.descValid(
            "Este nombre tiene mas de una coincidencia. Selecciona alguno de los usuarios por favor"
          );
          this.arregloBeneficiariosIncidentes = response;
          for (let i = 0; i < response.length; i++) {
            // const Validacliente = {
            //   option : "insertar2",
            //   texto: "		SELECT A.*,(SELECT C.Nombre FROM db_liwa.Seccionales C 	WHERE C.Id_seccional = A.Id_Seccional) AS Nombre_seccional	,(SELECT E.Nombre FROM db_liwa.tipoProyecto E WHERE E.id_tipoProyecto = A.Id_Proyecto) AS Nombre_proyecto, A.Id_Proyecto FROM db_liwa.AOM A WHERE (SELECT E.Id_Client FROM db_liwa.tipoProyecto E WHERE E.Id_Client = 7  AND E.id_tipoProyecto = A.Id_Proyecto)  AND A.IsDelete=0	 AND A.Id_Encuesta = '"+response[i][1]+"';"
            // }
            // this.global.consultar(Validacliente, (err, response1) => {
            //   if(response1){
            //     console.log(response1);

            //   }
            // });
            this.nombresIncidentes.push(response[i]);
          }
          console.log("PUSH Name", this.nombresIncidentes);
          this.banderaNombres = true;
          this.spinner.hide();
        } else {
          this.alerta.descErr(
            "No hemos encontrado ningun beneficiario Con este nombre, Lo sentimos mucho. puede intentar con la cedula el NUI o un numero de ticket asociado, Tenga en cuenta que NUI o Ticket no contenga espacios en vacíos"
          );
          this.spinner.hide();
        }
      });
    } else if (model) {
      this.global.consultar(consultaBeneficiario, (err, response1) => {
        // console.log(consultaBeneficiario, "estamos aqui!");
        if (response1.length > 0) {
          this.benefits = response1;
          this.global.informacionBeneficiario = this.benefits;
          window.localStorage.setItem(
            "informacion beneficiario",
            JSON.stringify(this.global.informacionBeneficiario)
          );
          // console.log(
          //   "retroalimentacion full a full ",
          //   this.global.informacionBeneficiario
          // );
          this.global.informacionBeneficiario = JSON.parse(
            window.localStorage.getItem("informacion beneficiario")
          );
          this.ruta.navigateByUrl("/informacion-usuario");
          this.spinner.hide();
        } else {
          this.alerta.descErr(
            "la Cedula o id Radicado no se encuentra registrado verefique he intente nuevamente"
          );
          this.spinner.hide();
        }
      });
    } else {
      this.alerta.descErr(
        "Agrega cualquieras de las referecias validas para hacer tu busqueda"
      );
      this.spinner.hide();
    }
  }

  usuarioSeleccionado() {
    console.log(
      "Datos de Arrya",
      this.arregloBeneficiariosIncidentes,
      "SELEC",
      this.nombre_relacionados
    );
    for (
      let index = 0;
      index < this.arregloBeneficiariosIncidentes.length;
      index++
    ) {
      if (
        this.arregloBeneficiariosIncidentes[index][0] ==
        this.nombre_relacionados
      ) {
        this.benefits.push(this.arregloBeneficiariosIncidentes[index]);
      }
    }
    console.log(this.benefits, ";vamos a ver si estas ");
    this.global.informacionBeneficiario = this.benefits;
    window.localStorage.setItem(
      "informacion beneficiario",
      JSON.stringify(this.global.informacionBeneficiario)
    );
    this.global.informacionBeneficiario = JSON.parse(
      window.localStorage.getItem("informacion beneficiario")
    );
    this.ruta.navigateByUrl("/informacion-usuario");
    this.spinner.hide();
  }
}
