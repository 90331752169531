import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalAlertService } from 'src/app/global-alert.service';
import { GlobalService } from 'src/app/global.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {
  medidores = [];
  MEDIDORESEXCEL;
  clientClass;
  Subir = false;
  Registrar = false;
  seccionales;
  seccionalOn;
  buttonSeccional;
  idSeccional;
  constructor(public global: GlobalService,
    public ruta: Router,
    public alerta: GlobalAlertService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.clientClass = this.global.CLIENTDATALIWA[8];
    const pdata1 = { option: 'insertar', texto: "SELECT `identificacion`, `name`, `last_name`, `address`, `state_code`, `city_code`, `postal_code`, `tel`, `email`, `nui`,`id_siigo` FROM db_liwa.Clientes_facturacion_sunpower  WHERE IsDelete = '0';" };
    console.log('listado', pdata1);
    this.global.consultar(pdata1, (err1, response1) => {
      console.log('Datos listado', response1);
      this.medidores = response1;
      $(function () {
        this.table = $('#seccionalTable').DataTable({
          'responsive': false,
          'destroy': true,
          'retrieve': true,
          'paging': true,
          'info': false,
          'pagingType': 'numbers',
          'language': {
            'url': '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json'
          }
        });
      });
    });
    const pdata4 = { option: 'insertar2', texto: `SELECT Nombre, Id_Seccional FROM db_liwa.Seccionales Where Id_Proyecto = '58' AND IsDelete=0 AND IsActive=1;` };
    this.global.consultar(pdata4, async (err4, response4) => {
      let res:any [] = await  response4;
      if (res.length == undefined || res.length == 0) {
        this.seccionales = [];
        this.seccionalOn = true;
        this.buttonSeccional = false;
        this.spinner.hide();
      } else {
        this.seccionales = response4;
        this.seccionalOn = true;
        this.spinner.hide();
      }
    });
  }

  SubirExcel() {
    this.Subir = true;
  }
  arrayBuffer: any;
  file: File;
  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      var MEDIDORESEXCEL = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      if (MEDIDORESEXCEL[0].hasOwnProperty('name')) {
        console.log('Si name')
        if (MEDIDORESEXCEL[0].hasOwnProperty('last_name')) {
          console.log('SI last_name')
          if (MEDIDORESEXCEL[0].hasOwnProperty('address')) {
            console.log('SI address')
            if (MEDIDORESEXCEL[0].hasOwnProperty('state_code')) {
              console.log('SI state_code')
              if (MEDIDORESEXCEL[0].hasOwnProperty('city_code')) {
                console.log('SI city_code')
                if (MEDIDORESEXCEL[0].hasOwnProperty('postal_code')) {
                  console.log('SI postal_code')
                  if (MEDIDORESEXCEL[0].hasOwnProperty('number')) {
                    console.log('SI number')
                    if (MEDIDORESEXCEL[0].hasOwnProperty('email')) {
                      console.log('SI email')
                      if (MEDIDORESEXCEL[0].hasOwnProperty('comments')) {
                        console.log('SI comments')
                        if (MEDIDORESEXCEL[0].hasOwnProperty('identification')) {
                          console.log('SI identification')
                          this.MEDIDORESEXCEL = MEDIDORESEXCEL;
                          this.Registrar = true;
                        } else {
                          console.log('No identification')
                          this.alerta.excelErr('identification');
                        }
                      } else {
                        console.log('No comments')
                        this.alerta.excelErr('comments');
                      }
                    } else {
                      console.log('No email')
                      this.alerta.excelErr('email');
                    }
                  } else {
                    console.log('No number')
                    this.alerta.excelErr('number');
                  }
                } else {
                  console.log('No postal_code')
                  this.alerta.excelErr('postal_code');
                }
              } else {
                console.log('No city_code')
                this.alerta.excelErr('city_code');
              }
            } else {
              console.log('No state_code')
              this.alerta.excelErr('state_code');
            }
          } else {
            console.log('No address')
            this.alerta.excelErr('address');
          }
        } else {
          console.log('No last_name')
          this.alerta.excelErr('last_name');
        }
      } else {
        console.log('No name')
        this.alerta.excelErr('name');
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  RegistrarMedidores() {
    this.spinner.show();
    let masivo = new Array();
    /*
    Apellidos: "Hernandez Osorio"
    Cedula: 1000687826
    Comunidad: "NA"
    Departamento: "Boyacá"
    Latitud: 1223456
    Longitud: 314234235
    Municipio: "Tunja"
    Nombres: "Miguel Felipe"
    Vereda: "Centro"
    */
    if (this.Registrar == true) {
      for (let i = 0; i < this.MEDIDORESEXCEL.length; i++) {
        masivo.push({
          identificacion: this.MEDIDORESEXCEL[i].identification,
          name: this.MEDIDORESEXCEL[i].name,
          last_name: this.MEDIDORESEXCEL[i].last_name,
          address: this.MEDIDORESEXCEL[i].address,
          state_code: this.MEDIDORESEXCEL[i].state_code,
          city_code: this.MEDIDORESEXCEL[i].city_code,
          postal_code: this.MEDIDORESEXCEL[i].postal_code,
          tel: this.MEDIDORESEXCEL[i].number,
          correo: this.MEDIDORESEXCEL[i].email,
          comments: this.MEDIDORESEXCEL[i].comments,
          id_seccional: this.idSeccional
        })
      }
      setTimeout(() => {
        console.log('MASIVO DEF', masivo);

        const pdata3 = { option: 'crear-clientes', datos:  masivo};
        this.global.consultar_siigo(pdata3, async (err3, response3) => {
          console.log("Envio Factura", response3);
          if(response3){
            this.spinner.hide();
            this.alerta.descValid('Proceso iniciado, esto se puede  llegar a demorar!');
            this.ngOnInit();
          }else if(response3 == null || response3 == undefined){
            this.spinner.hide();
            this.alerta.descErr('Error, por favor intente de nuevo!');
          }
      });
      }, 500);
    } else {

    }
  }


  IsActiveView(data) {
    if (data == 1) {
      return 'Activado';
    }
    if (data == 0) {
      return 'Desactivado';
    }
  }
  isActiveBadge(data) {
    if (data == 1) {
      return 'badge badge-success';
    }
    if (data == 0) {
      return 'badge badge-danger';
    }
    this.ngOnInit();
  }

  deleteSec(idSec) {
    Swal.fire({
      title: '<strong>¿Está seguro que desea eliminar el registro?</strong>',
      icon: 'question',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#093579',
      cancelButtonText: 'Cancelar',
      cancelButtonAriaLabel: 'Thumbs up, great!',
      confirmButtonText: 'Si, estoy seguro'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminar(idSec);
      } else {
        this.ngOnInit();
      }
    });
  }
  eliminar(data) {
    this.spinner.show();
    setTimeout(() => {
      var query = "UPDATE db_liwa.Clientes_facturacion_sunpower SET IsDelete = '1' WHERE Id ='" + data + "';";
      const pdata1 = { option: 'insertar', texto: query };
      $.ajax({
        data: pdata1, cache: false, dataType: 'json', type: 'post',
        url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
        success(data, textStatus, jQxhr) {
        },
        error(jqXhr, textStatus, errorThrown) {
        }
      }).then((response) => {
        this.alerta.descValid('Registro eliminado correctamente');
        this.ngOnInit();
        this.spinner.hide();
      }).catch((err) => {
      });
    }, 60);

  }


}
